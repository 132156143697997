import {
  createStyles,
  fade,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import styled, { css } from "styled-components";

export default makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: "100%",
      "@media (max-width: 960px)": {
        padding: "10px 0",
      },
    },
    listHeader: {
      display: "grid",
      gridTemplateColumns: "2fr 1fr",
      width: "100%",
      padding: "0 2rem",
    },
    listFont: {
      fontSize: "1.2rem",
      margin: "1.2rem 0",
      fontWeight: 400,
    },
    title: {
      fontSize: "1.2rem",
      textTransform: "uppercase",
      margin: "1.2rem 0",
      fontWeight: 700,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff",
      background: "#C0B021",
      padding: "16px",
    },
    divider: {
      height: "1px",
      margin: "1.6rem auto",
      backgroundColor: "#C0B021",
    },
    myOrderList: {
      display: "flex",
      justifyContent: "flex-end",
      fontSize: "1.2rem",
      margin: "1.2rem 0",
      fontWeight: 400,
    },
    img: {
      cursor: "pointer",
      marginLeft: "10px",
      width: "20px",
      height: "20px,",
    },
    colorName: {
      display: "flex",
      alignItems: "center",
    },
    buttonContainer: {
      marginTop: "30px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      "@media (max-width: 660px)": {
        flexDirection: "column",
      },
    },
    btnRegister: {
      marginLeft: "1rem",
      textTransform: "uppercase",
      padding: "0.35rem 1.2rem",
      position: "relative",
      overflow: "hidden",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      transition: ".5s background, border",
      border: `.1rem solid ${theme.palette.primary.main}`,
      background: theme.palette.primary.main,
      [theme.breakpoints.down("sm")]: {
        width: "33%",
        marginLeft: "0",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: ".6rem",
        width: "100%",
        paddingTop: ".58rem",
        paddingBottom: ".58rem",
      },
      "& span": {
        zIndex: 1,
        lineHeight: "100%",
        fontWeight: 600,
        color: "#ffffff",
        fontSise: 12,
        transition: ".5s color",
      },
      "&:hover": {
        borderColor: "#ffffff",
        background: "#ffffff",
        "& span": {
          color: theme.palette.primary.main,
        },
      },
    },
    btnCheck: {
      background: "#a89820",
      color: "#fff",
      padding: "0.35rem 1.2rem",
    },
  })
);

export const Arrow = styled.div`
  width: 0;
  margin: 7px;
  height: 0;
  cursor: pointer;
  border-left: 7px solid #c0b021;
  border-top: 8px solid transparent;
  border-bottom: 7px solid transparent;
  transform: ${(props) => props.theme.rotate};
`;
