import {
  createStyles,
  fade,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: fade(theme.palette.info.main, 0.12),
      padding: "30px 40px",
      width: "100%",
      height: "100%",
    },
    title: {
      fontSize: "2rem",
      margin: "1.2rem 0",
      fontWeight: 700,
      opacity: ".8",
      maxWidth:"200px",
    },
    subTitle:{
      fontSize:"1.4rem",
      marginBottom:"10px",
    },
    divider: {
      height: ".3rem",
      width: "100px",
      backgroundColor: "#C0B021",
    },

    description: {
      fontSize: "1.1rem",
      position: "relative",
      margin: "1.2rem 0",
      fontWeight: 400,
      height: "130px",
      overflowX: "auto",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  })
);
