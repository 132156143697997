import React, { useState, useContext, useEffect, Children } from "react";
import i18n from "i18next";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Hidden,
  Collapse,
  useMediaQuery,
  Checkbox,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import { ExpandMoreLightIcon, SliderIcon } from "../CustomIcons";
import { DefaultValues, SearchContext } from "Context/Search/SearchContext";
import {
  GeneralsContext,
  GeneralsContextInterface,
} from "Context/Generals/GeneralsContext";

import useStyles from "./styles";
import { useTranslation } from "react-i18next";

interface ListFilter {
  key: string;
  label: string;
  id: number | null;
}

export default function Filter() {
  const classes = useStyles();
  const { categories, attributes, groupAttributes } =
    useContext<GeneralsContextInterface>(GeneralsContext);
  const theme = useTheme();
  const { t } = useTranslation();
  const searchState = DefaultValues;
  const { setSearchFilter } = useContext(SearchContext);
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [chipData, setChipData] = useState<ListFilter[] | []>([]);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDropdawn, setOpenDropdawn] = useState(false);
  const initialState = useContext(SearchContext);
  const [atributes, setAtributes] = useState<number[]>([]);

  const handleDelete = (chipToDelete: ListFilter) => () => {
    setChipData((chips) =>
      chips.filter((chip: ListFilter) => chip.key !== chipToDelete.key)
    );
    const aux = { ...initialState };
    switch (chipToDelete.key) {
      case "order":
        searchState.ordenBy = "name";
        break;
      case "collection":
        aux.idCategories = null;
        break;
      case "type":
        const currentIndex = atributes.indexOf(chipToDelete.id || 0);
        const newChecked = [...atributes];
        newChecked.splice(currentIndex, 1);
        setAtributes(newChecked);
        aux.idAttributes = newChecked.toString();
        break;
      default:
        break;
    }
    setSearchFilter(aux);
  };

  const clearFilter = () => {
    setChipData([]);
    searchState.ordenBy = "name";
    searchState.Collection = "";
    searchState.type = "";
    setSearchFilter(searchState);
    setAtributes([]);
  };

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const getNameByValue = (value: number, type: string) => {
    switch (type) {
      case "collection":
        const collection = categories.find((c) => value === c.id);
        return i18n.language === "ES" ? collection?.nameEs : collection?.nameEn;
      case "type":
        const type = attributes.find((c) => value === c.id);
        return i18n.language === "ES" ? type?.nameEs : type?.nameEn;
      default:
        break;
    }
  };
  const handleChangeRadioGroupOne = (event: {
    target: { value: string; name: string };
  }) => {
    const { name, value } = event.target;
    const toNumber = Number(value);
    const aux = { ...initialState };
    switch (name) {
      case "order":
        aux.ordenBy = value;
        break;
      case "collection":
        aux.idCategories = toNumber;
        break;
      case "type":
        const currentIndex = atributes.indexOf(toNumber);
        const newChecked = [...atributes];
        if (currentIndex === -1) {
          newChecked.push(toNumber);
        } else {
          newChecked.splice(currentIndex, 1);
        }
        setAtributes(newChecked);
        aux.idAttributes = newChecked.toString();
        const data: ListFilter[] = newChecked.map((item) => {
          return {
            key: "type",
            label: getNameByValue(Number(item), "type") || "",
            id: item,
          };
        });
        const asd = [...chipData];
        const filters = asd.find((es: ListFilter) => es.key === "collection");
        if (filters) data.push(filters);
        setChipData(data);
        break;
      default:
        break;
    }
    if (name === "collection") {
      const data: ListFilter = {
        key: "collection",
        label:
          getNameByValue(Number(initialState?.idCategories), "collection") ||
          "",
        id: initialState?.idCategories || null,
      };
      const asd = [...chipData];
      const filter = asd.filter((es: ListFilter) => es.key === "collection");
      if (!filter.length) {
        asd.push(data);
      }
      const filters = asd.map((es: ListFilter) => {
        if (es.key === "collection") {
          es = data;
        }
        return es;
      });
      setChipData(filters);
    }
    setSearchFilter(aux);
  };

  useEffect(() => {
    if (initialState.idCategories) {
      const data: ListFilter = {
        key: "collection",
        label:
          getNameByValue(Number(initialState?.idCategories), "collection") ||
          "",
        id: initialState?.idCategories,
      };
      const asd = [...chipData];
      const filter = asd.filter((es: ListFilter) => es.key === "collection");
      if (!filter.length) {
        asd.push(data);
      }
      const filters = asd.map((es: ListFilter) => {
        if (es.key === "collection") {
          es = data;
        }
        return es;
      });
      setChipData(filters);
    }
    if (initialState.idAttributes) {
      const data: ListFilter[] = atributes.map((item) => {
        return {
          key: "type",
          label: getNameByValue(Number(item), "type") || "",
          id: item,
        };
      });
      const asd = [...chipData];
      const filters = asd.find((es: ListFilter) => es.key === "collection");
      if (filters) data.push(filters);
      setChipData(data);
    }
  }, [initialState, i18n.language]);

  const handleClick = () => {
    setOpenDropdawn(!openDropdawn);
  };

  return (
    <Box>
      <Hidden only={["md", "lg", "xl"]}>
        <Box onClick={handleClick} className={classes.headerCollapse}>
          <SliderIcon color="primary" className={classes.iconFilter} />
          <Typography variant="h3" className={classes.titleSectionFiltro}>
            {t("Applied Filters")}
          </Typography>
          <Box className={classes.count}>
            <span>{chipData.length}</span>
          </Box>
        </Box>
      </Hidden>
      <Collapse
        in={matches ? openDropdawn : true}
        timeout="auto"
        unmountOnExit
        disableStrictModeCompat={false}
      >
        <Hidden only={["sm", "xs"]}>
          <Typography variant="h3" className={classes.titleSectionFiltro}>
            {t("Applied Filters")}
          </Typography>
        </Hidden>
        <Box className={classes.contFilter}>
          <ul className={classes.contChips}>
            {Children.toArray(
              chipData.map((data) => {
                return (
                  <li>
                    <Chip
                      label={data.label}
                      onDelete={handleDelete(data)}
                      className={classes.chip}
                    />
                  </li>
                );
              })
            )}
          </ul>
          {chipData.length > 0 && (
            <Box>
              <Typography
                variant="button"
                onClick={clearFilter}
                className={classes.btnClean}
              >
                {t("Clean Filters")}
              </Typography>
            </Box>
          )}
          <Divider className={classes.divider} />
        </Box>
        <Box className={classes.contAccordion}>
          <Accordion
            square
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className={classes.itemAccordion}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreLightIcon color="primary" />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className={classes.titleAccordion}
            >
              <Typography>{t("Order by")}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.bodyAccordion}>
              <FormControl
                component="fieldset"
                className={classes.GroupRadioButton}
              >
                <RadioGroup
                  aria-label="order"
                  name="order"
                  value={initialState.ordenBy}
                  onChange={handleChangeRadioGroupOne}
                >
                  <FormControlLabel
                    value="name"
                    control={<Radio color="primary" />}
                    label={t("Name increasing")}
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="-name"
                    control={<Radio color="primary" />}
                    label={t("Name decreasing")}
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="price_amount"
                    control={<Radio color="primary" />}
                    label={t("Price low to high")}
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="-price_amount"
                    control={<Radio color="primary" />}
                    label={t("Price high to low")}
                    labelPlacement="start"
                  />
                </RadioGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>
          {Children.toArray(
            groupAttributes.map((item, index) => {
              if (item.attributeSet.length && item.name) {
                return (
                  <Accordion
                    square
                    expanded={expanded === `panel${index + 3}`}
                    onChange={handleChange(`panel${index + 3}`)}
                    className={classes.itemAccordion}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreLightIcon color="primary" />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      className={classes.titleAccordion}
                    >
                      <Typography style={{textTransform: "capitalize"}}>
                        {i18n.language === "EN" ? item.nameEn.toLowerCase()? item.nameEn.toLowerCase(): item.nameEs.toLowerCase() : item.nameEs.toLowerCase()}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.bodyAccordion}>
                      <FormControl
                        component="fieldset"
                        className={classes.GroupRadioButton}
                      >
                        {Children.toArray(
                          item.attributeSet.map((itemx) => (
                              <>
                                <FormControlLabel
                                  name="type"
                                  value={itemx.id}
                                  control={
                                    <Checkbox
                                      checked={atributes.indexOf(itemx.id) !== -1}
                                      name="type"
                                      color="primary"
                                      onChange={handleChangeRadioGroupOne}
                                    />
                                  }
                                label={
                                    i18n.language === "EN" ? itemx.nameEn.toLowerCase()? itemx.nameEn.toLowerCase(): itemx.nameEs.toLowerCase() : itemx.nameEs.toLowerCase()
                                  }
                                labelPlacement="start"
                                style={{textTransform:'capitalize'}}
                                />
                              </>)
                          )
                        )}
                      </FormControl>
                    </AccordionDetails>
                  </Accordion>
              );
              }
              else {
                return (<></>);
            }
            })
          )}
        </Box>
      </Collapse>
    </Box>
  );
}
