import {
  makeStyles,
  Theme,
} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => ({
  tableHead: {
    "& th": {
      fontSize: "1.2rem",
      textAlign: "center",
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      paddingBottom: "30px",
      paddingTop: "30px",
      color: theme.palette.primary.main,
    },
  },
  tableBody: {
    "& td": {
      fontSize: "1.2rem",
      textAlign: "center",
    },
  },
  imgUrl: {
    maxWidth: "70px",
    maxHeight: "70px",
  },
  imgDelete: {
    cursor: "pointer",
    width: "20px",
    height: "20px,",
  },
  imgColor: {
    "& div" : {
      marginRight: "0px",
      display:"flex",
      justifyContent: "center",
      cursor: "auto",
    }
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    paddingBottom: "30px",
    paddingTop: "30px",
    marginTop: "50px",
    marginBottom: "40px",
  },
  titleNoProducts: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    paddingBottom: "30px",
    paddingTop: "30px",
  },
  btnSuccess: {
    background: theme.palette.primary.main,
    color: "#ffffff",
    width: "fit-content",
    borderRadius: "0",
    fontSize: 16,
    padding: "0.6rem 1rem",
    border: `2px solid ${theme.palette.primary.main}`,
    marginTop: "1.6rem",
    transition: "0.4s ease all",
    fontWeight: 500,
    letterSpacing: ".05rem",
    [theme.breakpoints.down("xs")]: {
        fontSize: 15,
        marginTop: "1.2rem",
    },
    "&:hover": {
        backgroundColor: "#ffffff",
        color: theme.palette.primary.main,
    },
  },
  btnContainer: {
    marginTop: "30px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  btnContainerAlert: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  alertContainer: {
    marginTop: "30px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  link: {
    color: theme.palette.primary.main,
  },
  cardContainer: {
    display: 'flex',
    marginBottom: "20px",
    boxShadow:"none",
    borderRadius: "0px",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    paddingBottom: "20px",
    paddingTop: "20px",
    justifyContent: "space-evenly",
    textAlign: "left",
  },
  detailsCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: "center",
  },
  contentCard: {
    flex: '1 0 auto',
    padding: "0",
  },
  imgCard: {
    width: 128,
    height: 130,
  },
  deleteCard :{
    flex: '1 0 auto',
    display: "flex",
    alignItems:"stretch",
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    "& img" : {
      marginLeft: "10px",
    },
  },
  titleCard: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    marginTop: "5px",
    marginBottom: "10px",
  },
  emptyCard: {
    color: theme.palette.primary.main,
    marginTop: "5px",
    marginBottom: "10px",
    fontSize: "1.2rem",
  },
  emptyCart: {
    color: theme.palette.primary.main,
    marginTop: "5px",
    marginBottom: "10px",
    fontSize: "1.2rem",
  },
  imgColorCard: {
    display: "flex",
    "& div" : {
      width: "35px",
      height: "17px",
      boxShadow: "none",
    },
    "& p" : {
      paddingRight: "6px",
    }
  }
}));

 