// Import core modules
import React, {useState, useContext, MouseEvent } from "react";
import Axios from "axios";
import {Link, useHistory} from 'react-router-dom';
import { Field, Form, Formik } from "formik";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

// Import Material Ui / Formik Related
import { Box, Container, Grid, Typography, FormControl,Button, InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { TextField } from "formik-material-ui";

// Import Locals
import ConfirmModal from "Components/ConfirmModal";
import { DefaultValues, UserContext } from "Context/UserSesion/UserContext";
import useStyles from "./styles";

const INITIAL_VALUES = {
    email: "",
    password: "",
};


interface Body {
    email: string;
    password: string;
}


export default function LoginPage() {
    const classes = useStyles();
    const { t } = useTranslation();



    const [showPassword, setShowPassword] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const { setUserState } = useContext(UserContext);
    const { setUserToken } = useContext(UserContext);

    const userData = DefaultValues;
    const apiBase: string = process.env.REACT_APP_API_LINK as string;
    let token = "";
    const history = useHistory();

    const validationSchema = () =>
      Yup.object().shape({
        email: Yup.string().required().email(),
        password: Yup.string()
          .required()
          .min(8, t("The password must have a minimum of 8 characters.")),
      });
    const mutation = useMutation(
        (data: Body) => Axios.post(apiBase + "api-token-auth/", data),
        {
            onError: (error) => {
                setShowConfirm(true)
            },
            onSuccess: (data, variables) => {
                token = data.data["token"];
                localStorage.setItem('kuna.stockservice-tokenAuth',token);
                userData.email = variables.email;
                userData.name = variables.email;
                userData.authenticated = true;
                userData.token = token
                setUserState(userData);
                setUserToken(token);
                history.push('/')
            },
        }
    );

    function Submit(username: string, password: string) {
        const body: Body = {
            email: username,
            password,
        };

        mutation.mutate(body);
    }
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: MouseEvent) => {
        event.preventDefault();
    };

    const errorLogin = ()=>{
        setShowConfirm(false)
    }

    return (
        <>
            <Grid container className={classes.contBanner}>
                <Container maxWidth="md">
                
                    <Typography
                        variant="h1"
                        component="h2"
                    >
                        {t('Sign in.')}
                    </Typography>
                    <div>
                    <Formik
                        initialValues={INITIAL_VALUES}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(false);
                            Submit(values.email, values.password);
                            resetForm({ values: INITIAL_VALUES });
                        }}
                    >
                        {({ submitForm, isSubmitting }) => (
                        <Form>
                            <FormControl fullWidth className={classes.formControl}>
                            <Field
                                component={TextField}
                                fullWidth
                                name="email"
                                type="email"
                                label={t("Email")}
                            />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <Field 
                                    component={TextField}
                                    fullWidth
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    label={t("Password")}
                                />
                                <InputAdornment position="end" className={classes.iconPass}>
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            </FormControl>
                            <Box display="flex" justifyContent="center">
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}
                                type="submit"
                                className={classes.btnSend}
                            >
                                {t('Login')}
                            </Button>
                            </Box>
                        </Form>
                        )}
                    </Formik>
                    <div className={classes.contTextNote}>
                        <Link to='/resetPassword' className={classes.textNote}>
                            {t('Forgot your password?')} <span className="u-link">{t('Click here.')}</span>
                        </Link>
                        <Link to='/register' className={classes.textNote}>
                            {t('Don´t have an account?')}{" "}
                            <span className="u-link">
                                {t('Register.')}
                            </span>
                        </Link>
                    </div>
                    </div>
                </Container>
            </Grid>

            <ConfirmModal  
                openModal={showConfirm} 
                closeModal={() => errorLogin()} 
                message={t('Your email address and/or password do not match')}
                title="Error"
            />  
        </>
    );
}
