import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Divider, Grid, Typography, Tooltip, IconButton } from "@material-ui/core";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { StockListProps } from "Common/Types/Products";
import ColorList from "Components/ColorList";
import useStyles, { Arrow } from "./styles";

export default function StockList({
  colorList,
  changeOrder,
  anyToShopping,
  handleAddSummary,
  isDisabled,
  editMode,
  changeEditMode,
}: StockListProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  
  return (
    <Box className={classes.wrapper}>
      <Typography variant="h3" color="primary" className={classes.title}>
        {t("Available Stocks")}
        <div className={classes.avaliableStock}>
          <ColorList changeColor={() => {}} colorListDetails={colorList} />
        </div>
        <Tooltip arrow title={
          <React.Fragment>
            <Typography color="inherit" variant="h6"> {t("How to buy")}</Typography>
            <ol>
              <li><Typography color="inherit" variant="body2">{t("Choose the color of the garment.")}</Typography></li><br/>
              <li><Typography color="inherit" variant="body2">{t("Add the quantity of the product you want per size. If the product is not available, you can still add it to the shopping cart. We will confirm its availability when we receive the order.")}</Typography></li><br/>
              <li><Typography color="inherit" variant="body2">{t("Add the products to the order summary.")}</Typography></li><br/>
              <li><Typography color="inherit" variant="body2">{t("You can select another color of the same model, add the sizes you want to order and add them to the order summary.")}</Typography></li><br/>
              <li><Typography color="inherit" variant="body2">{t("Finally, add the products to the shopping cart.")}</Typography></li>
            </ol>
          </React.Fragment>
        }>
          <IconButton color="secondary" size="small">
            <HelpOutlineIcon/>
          </IconButton>
        </Tooltip>
      </Typography>
      <Grid container>
        <Box className={classes.listHeader}>
          <Typography
            variant="h3"
            color="primary"
            className={classes.listHeaderTittle}
          >
            {t("Size")}
          </Typography>
          <Box className={classes.stock}>
            <Typography
              variant="h3"
              color="primary"
              className={classes.listHeaderTittle}
            >
              {t("Available Stock")}
            </Typography>
          </Box>
          <Box className={classes.myOrder}>
            <Typography
              variant="h3"
              color="primary"
              className={classes.listHeaderTittle}
            >
              {t("My Order")}
            </Typography>
          </Box>
        </Box>
        <Divider className={classes.listDivider} />
        {colorList[0]?.stock.length ? (
          <>
            {React.Children.toArray(
              colorList[0]?.stock.map((item) => (
                <Box className={classes.listHeaderContainer}>
                  <Box className={classes.listHeader}>
                    <Typography
                      variant="h5"
                      color="secondary"
                      className={classes.listFont}
                    >
                      {item.size}
                    </Typography>
                    <Box className={classes.stock}>
                      <Typography
                        align="left"
                        variant="h5"
                        color="secondary"
                        className={classes.listFont}
                      >
                        {item.avaliableStock} {t("units")}
                      </Typography>
                    </Box>
                    <div className={classes.myOrderList}>
                      <input
                        type="text"
                        disabled={isDisabled}
                        className={classes.inputOrder}
                        value={item.myOrder}
                        onChange={(e) => changeOrder("write", item, e)}
                      ></input>
                    </div>
                  </Box>
                  <Divider className={classes.listDividerInternal} />
                </Box>
              ))
            )}
          </>
        ) : (
          <div className={classes.noavailable}>
            <Typography color="primary">{t("No stock available.")}</Typography>
          </div>
        )}
      </Grid>

      <Box className={classes.buttonContainer}>
        {!isDisabled && anyToShopping && (
          <>
            <Button
              className={classes.btnRegister}
              onClick={() => handleAddSummary()}
            >
              {!editMode ? (
                <span>{t("ADD PRODUCTS TO SUMARY")}</span>
              ) : (
                <span>{t("UPDATE PRODUCTS TO SUMARY")}</span>
              )}
            </Button>

            {editMode && (
              <Button
                disabled={isDisabled}
                className={classes.btnRegister}
                onClick={() => changeEditMode()}
              >
                <span>CANCEL</span>
              </Button>
            )}
          </>
        )}
      </Box>
      <Box className={classes.footerMgs}>
        <Typography>
          {t(
            'Go to the "Product summary" section to see the summary of your order.'
          )}
        </Typography>
      </Box>
    </Box>
  );
}
