import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    contBanner: {
      margin: "6rem auto",
      maxWidth: "1200px",
      minHeight: "50vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      "& >div": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      "&>h1, h2": {
        fontSize: "3.4rem",
        textAlign: "center",
        marginTop: "40px",
        marginBottom: "30px",
      },
    },
    title: {
      fontSize: "3rem",
      height: "80px",
    },
    titleAccordion: {
      fontSize: "18px",
      fontWeight: "bold",
    },
    subtitle: {
      fontSize: "16px",
      fontWeight: "bold",
      marginBottom: "4px",
    },
    text: {
      fontSize: "15px",
      marginBottom: "4px",
    },
    boxAccordion: {
      width: "800px",
      "& .MuiAccordionDetails-root": {
        flexDirection: "column",
        textAlign: "left",
      },
      "& .MuiSvgIcon-root": {
        width: "12px",
      },
      "& .MuiAccordionSummary-root": {
        borderBottom: "1px solid #C0B021",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "20%",
        width: "300px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "700px",
      },
    },
  })
);
