import { FC, useEffect, useState } from "react";
import * as moment from "moment";

import {
  DefaultValues,
  UserContext,
  UserContextInterface,
} from "./UserContext";

const MINUTE = 60000;

const getInterval = (exp: any) => {
  const now = moment.now();
  const expiresAt = moment.unix(exp);
  const duration = moment.duration(expiresAt.diff(now)).asMilliseconds();

  return duration - MINUTE;
};

const UserProvider: FC = ({ children }) => {
  const [userState, setUserState] =
    useState<UserContextInterface>(DefaultValues);
  const [userToken, setUserToken] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("kuna.stockservice-tokenAuth");
    if (!userState.authenticated && token != null) {
      setUserState((prevState) => ({
        ...prevState,
      }));
      setUserToken(token);
    }
  }, [userState.authenticated, userToken]);

  let tokenExpire: any = null;
  let dataRefreshToken: any;
  let getRefreshToken: any;

  const apiBase: string = process.env.REACT_APP_API_LINK as string;

  const setRefreshToken = async (token: any, force = false) => {
    if (tokenExpire !== null) {
      clearTimeout(tokenExpire);
    }

    let interval = 100;

    localStorage.setItem("kuna.stockservice-tokenAuth", JSON.stringify(token));

    if (!force) {
      interval = getInterval(360000000);
    }
    if (interval > 0) {
      tokenExpire = setTimeout(() => {
      }, interval);
    }
  };

  const getContext = () => ({
    ...userState,
    setUserToken,
    setRefreshToken,
    setUserState,
  });

  return (
    <UserContext.Provider value={getContext()}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
