import React, { Children, useContext, useEffect, useState } from "react";
import { Img } from "react-image";
import { Link, useHistory } from "react-router-dom";

import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Hidden,
  Card,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import deleteImg from "Assets/images/delete.svg";
import { ShoppingPropsModel, ToShoppingModel } from "Common/Types/Products";
import ColorList from "Components/ColorList";
import ConfirmModal from "Components/ConfirmModal";
import { shoppingContext } from "Context/Shopping/ShoppingContext";
import { useTranslation } from "react-i18next";
import api from "Utils/AxiosConfig";
import useStyles from "./styles";
import { useMutation } from "react-query";

const headerTable = [
  "Product",
  "ID",
  "Image",
  "Color",
  "Size",
  "Quantity",
  "Price",
  "Sub-Total",
  "Delete",
];

export default function ProductShopping({ products }: ShoppingPropsModel) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showConfirm, setShowConfirm] = useState(false);
  const [quotationIdCreated, setQuotationIdCreated] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showAlertStock, setShowAlertStock] = useState(false);
  const history = useHistory();
  const { shopping, dispatch } = useContext(shoppingContext);
  const productList = shopping.products;
  const [sendData, setSendData] = useState<any>([]);

  const filt = products.filter(
    (element) => element.myOrder > element.avaliableStock
  );

  const deleteSummary = (elemt: ToShoppingModel) => {
    const filterProduct = productList.filter(
      (item: { code: string }) => elemt.code !== item.code
    );
    dispatch({
      type: "ADD_PRODUCTS",
      products: filterProduct,
    });
    alertStock();
  };

  useEffect(() => {
    const formatDataToSend = products.map((item) => {
      return {
        amount: item.myOrder,
        colour: item.id,
        size: item.sizeId,
      };
    });
    const toSend = {
      item_set: formatDataToSend,
    };
    setSendData(toSend);
    alertStock();
  }, [products]);

  const handleCheckout = async () => {
    mutation.mutate(sendData);
  };

  const alertStock = () => {
    if (filt.length) setShowAlertStock(true);
    else setShowAlertStock(false);
  };

  const mutation = useMutation(
    (data: Body) => api.post("/api/quotations/quotations/", data),
    {
      onError: (error) => {
        setShowError(true);
      },
      onSuccess: (response) => {
        dispatch({
          type: "ADD_PRODUCTS",
          products: [],
        });
        setQuotationIdCreated(response.data?.id);
        successQuotation();
      },
    }
  );

  const successQuotation = () => {
    setShowConfirm(true);
  };

  return (
    <>
      <Hidden only={["sm", "xs"]}>
        {products.length ? (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className={classes.tableHead}>
                    {Children.toArray(
                      headerTable.map((title) => (
                        <TableCell>{t(title)}</TableCell>
                      ))
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Children.toArray(
                    products.map((item) => (
                      <TableRow className={classes.tableBody}>
                        <TableCell>
                          {item.name} <br /> {item.color[0]?.color}
                        </TableCell>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>
                          <img src={item.image} className={classes.imgUrl} />
                        </TableCell>
                        <TableCell className={classes.imgColor}>
                          <ColorList
                            changeColor={() => {}}
                            colorListDetails={item.color}
                          />
                        </TableCell>
                        <TableCell>{item.size}</TableCell>
                        <TableCell>{item.myOrder}</TableCell>
                        <TableCell>USD$ {item.price}</TableCell>
                        <TableCell>USD$ {item.price * item.myOrder}</TableCell>
                        <TableCell>
                          <Img
                            src={deleteImg}
                            className={classes.imgDelete}
                            onClick={() => {
                              deleteSummary(item);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {showAlertStock && (
              <Box className={classes.alertContainer}>
                <Alert severity="warning">
                  {t(
                    "Some orders exceed the current stock, the supplier will contact you."
                  )}{" "}
                  <br />
                  {t("You can still send the quotation.")}
                </Alert>
              </Box>
            )}
            <Box className={classes.btnContainerAlert}>
              
                <Button className={classes.btnSuccess} onClick={handleCheckout}> 
                <span>{t("SEND QUOTATION")}</span>
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography
              variant="h3"
              color="primary"
              className={classes.emptyCart}
            >
              {t("There is nothing added to the shopping cart. Keep browsing ")}
              <Link className={classes.link} to="/search">
                {t("here.")}
              </Link>
            </Typography>
          </>
        )}
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        {products.length ? (
          <>
            {Children.toArray(
              products.map((item) => (
                <Card className={classes.cardContainer}>
                  <CardMedia
                    component="img"
                    className={classes.imgCard}
                    src={item.image}
                  />
                  <div className={classes.detailsCard}>
                    <CardContent className={classes.contentCard}>
                      <Typography className={classes.titleCard}>
                        {" "}
                        {item.name}{" "}
                      </Typography>
                      <div className={classes.imgColorCard}>
                        <Typography> {t("Color")}:</Typography>
                        <ColorList
                          changeColor={() => {}}
                          colorListDetails={item.color}
                        />
                      </div>
                      <Typography>
                        {" "}
                        {t("Size")}: {item.size}{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        {t("Quantity")}: {item.myOrder}{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        {t("Price")}: {item.price}
                      </Typography>
                      <Typography>
                        {" "}
                        Sub-Total: {item.price * item.myOrder}{" "}
                      </Typography>
                    </CardContent>
                  </div>
                  <div className={classes.detailsCard}>
                    <CardContent className={classes.deleteCard}>
                      <Img
                        src={deleteImg}
                        className={classes.imgDelete}
                        onClick={() => {
                          deleteSummary(item);
                        }}
                      />
                    </CardContent>
                  </div>
                </Card>
              ))
            )}
            {showAlertStock && (
              <Box className={classes.alertContainer}>
                <Alert severity="warning">
                  {t(
                    "Some orders exceed the current stock, the supplier will contact you."
                  )}{" "}
                  <br />
                  {t("You can still send the quotation.")}
                </Alert>
              </Box>
            )}
            <Box className={classes.btnContainerAlert}>
              <Button className={classes.btnSuccess} onClick={handleCheckout}>
                <span>{t("SEND QUOTATION")}</span>
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography
              variant="h3"
              color="primary"
              className={classes.emptyCard}
            >
              {t("There is nothing added to the shopping cart. Keep browsing ")}
              <Link className={classes.link} to="/search">
                {t("here.")}
              </Link>
            </Typography>
          </>
        )}
      </Hidden>

      <ConfirmModal
        openModal={showConfirm}
        closeModal={() => history.push(`/quotation/${quotationIdCreated}/`)}
        message={t(
          "Your quote has been sent successfully. Your request will be evaluated and responded to your email"
        )}
        title={t("Success")}
      />
      <ConfirmModal
        openModal={showError}
        closeModal={() => setShowError(false)}
        message={t(
          "There's a problem with your order. Please wait a moment and try again. If the problem persist contact support@stockservice.com"
        )}
        title={t("Oh no")}
      />
    </>
  );
}
