import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function SearchIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 17.65 18.25">
      <path d="M8.09,0c0.45,0.07,0.9,0.14,1.34,0.27c1.34,0.4,2.49,1.11,3.43,2.14c1.02,1.12,1.64,2.43,1.85,3.93
		c0.15,1.03,0.07,2.06-0.22,3.06c-0.27,0.94-0.71,1.8-1.33,2.56c-0.09,0.11-0.07,0.17,0.02,0.26c1.4,1.45,2.8,2.9,4.19,4.37
		c0.54,0.57,0.28,1.46-0.46,1.63c-0.37,0.09-0.69-0.02-0.96-0.29c-0.41-0.43-0.82-0.85-1.24-1.28c-0.86-0.9-1.73-1.8-2.59-2.7
		c-0.13-0.13-0.26-0.26-0.38-0.4c-0.1-0.12-0.18-0.11-0.3-0.03c-0.69,0.47-1.45,0.81-2.27,1c-1.68,0.4-3.31,0.28-4.88-0.47
		c-2.31-1.12-3.72-2.95-4.18-5.47c-0.28-1.54-0.07-3.04,0.65-4.45c1.15-2.26,2.99-3.6,5.47-4.06C6.4,0.04,6.57,0.02,6.73,0
		C7.18,0,7.63,0,8.09,0z M7.39,12.78c2.99,0,5.43-2.43,5.42-5.41c-0.01-3.02-2.41-5.43-5.43-5.42C4.33,1.95,1.97,4.39,1.98,7.37
		C1.98,10.42,4.45,12.78,7.39,12.78z"/>
    </SvgIcon>
  )
}
