// Import core modules
import React, { Children, useContext, Dispatch } from "react";
import { Link } from "react-router-dom";
import i18n from "i18next";

// Import Locals
import useStyles from "Components/MenuSidebar/styles";
import { DefaultValues, SearchContext } from "Context/Search/SearchContext";

interface SubMenuProps {
  subCat: Array<ISubCategory>;
  closeSubMenu: Dispatch<React.SetStateAction<boolean>>;
}

interface ISubCategory {
  id: number;
  attribute: {
    id: number;
    name: string;
    name_es: string;
    name_en: string;
  };
}

export default function SubMenu({ subCat, closeSubMenu }: SubMenuProps) {
  const classes = useStyles();
  const stateDefault = DefaultValues;
  const { setSearchFilter } = useContext(SearchContext);

  const handleItemMenu = (categoryId: number) => {
    setSearchFilter({ ...stateDefault, idAttributes: categoryId });
    closeSubMenu(false);
  };

  return (
    <ul className={classes.navColumn}>
      {Children.toArray(
        subCat.map((category) => (
          <li>
            <Link
              to="/search"
              onClick={() => handleItemMenu(category.attribute.id)}
            >
              {i18n.language === "ES"
                ? category.attribute.name_es
                : category.attribute.name_en}
            </Link>
          </li>
        ))
      )}
    </ul>
  );
}
