import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function ExpandMoreIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 34.51 25.98">
      <path d="M34.51,2.97c-0.41,1.53-1.43,2.71-2.29,3.97c-3.37,4.95-6.77,9.89-10.15,14.84c-0.51,0.74-1.01,1.48-1.52,2.22
		c-0.37,0.53-0.79,1-1.33,1.37c-1.36,0.92-3.06,0.79-4.29-0.3c-0.74-0.66-1.23-1.51-1.78-2.31C9.09,16.85,5.05,10.94,1.01,5.03
		C0.47,4.24,0.01,3.4,0,2.4c-0.01-0.98,0.5-1.71,1.41-2.08c0.62-0.25,1.27-0.31,1.91-0.31C12.62,0,21.91,0,31.21,0
		c0.34,0,0.67,0.02,1.01,0.07c1.08,0.16,1.93,0.64,2.3,1.75C34.51,2.2,34.51,2.59,34.51,2.97z"/>
    </SvgIcon>
  )
}
