// Import core modules
import React, {useState, useEffect} from "react";
import {useLocation, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";

// Import Material Ui / Formik Related
import { Container, Grid, Typography} from "@material-ui/core";

// Import Locals
import PasswordResetForm from 'Components/PasswordResetForm';
import EmailResetForm from 'Components/EmailResetForm';


import useStyles from "./styles";

interface PasswordReset{
    token:string
}

export default function PasswordResetPage() {
    const classes = useStyles();
    const {token} = useParams<PasswordReset>();
    const [newPassword, setNewPassword] = useState(false);
    const { t }= useTranslation();

    useEffect(()=>{
        if(token){
            setNewPassword(true)
        }
    }, [token])


    return (
        <>
            <Grid container className={classes.contBanner}>
                <Container maxWidth="md">
                
                    <Typography
                        variant="h1"
                        component="h2"
                    >
                        {t('Password recovery')}
                    </Typography>
                    <div>
                        {newPassword ? (
                            <PasswordResetForm token={token}/>
                        ): (
                            <EmailResetForm />
                        )}
                    </div>
                </Container>
                
            </Grid>
        </>
    );
}
