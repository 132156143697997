import React from "react";
import { Img } from "react-image";

import { Box } from "@material-ui/core";

import { ColorListProps } from "Common/Types/Products";
import useStyles, { Circle } from "./styles";

export default function ColorList({
  colorList,
  changeColor,
  colorListDetails,
}: ColorListProps) {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.wrapper}>
        {colorList && (
          <>
            {React.Children.toArray(
              colorList?.map((item) => (
                <Circle
                  onClick={(event) => changeColor(item.id, event)}
                  theme={{ active: item.active, color: item.id }}
                >
                  <Img
                    src={
                      item.colourphotoSet && item.colourphotoSet.length > 0
                        ? item.colourphotoSet[0]?.photoThumbnail
                        : "https://plantillasdememes.com/img/plantillas/imagen-no-disponible01601774755.jpg"
                    }
                    alt="logo"
                    className={classes.photo}
                  />
                </Circle>
              ))
            )}
          </>
        )}
        {colorListDetails && (
          <>
            {React.Children.toArray(
              colorListDetails?.map((item) => (
                <Circle
                  onClick={(event) => changeColor(item.id, event)}
                  theme={{ active: item.active }}
                >
                  <Img
                    src={
                      item.imageList && item.imageList.length > 0
                        ? item.imageList[0]?.photoThumbnail
                        : "https://plantillasdememes.com/img/plantillas/imagen-no-disponible01601774755.jpg"
                    }
                    alt="logo"
                    className={classes.photo}
                  />
                </Circle>
              ))
            )}
          </>
        )}
      </Box>
    </>
  );
}
