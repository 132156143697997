import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: "100%",
    },
    bigViewContainer: {
      display: "grid",
      gridTemplateColumns: "80px 1fr",
    },

    minViewContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    bigViewList: {
      height: "425px",
      overflowY: "scroll",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "6px",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },

    minViewList: {
      maxWidth: "425px",
      overflowX: "scroll",
      display: "flex",
      alignItems: "center",
      padding: "6px",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "@media (max-width: 500px)": {
        maxWidth: "220px",
      },
    },
    minMinViewImg: {
      width: "80px",
      height: "80px",
      marginRight: "7px",
      cursor: "pointer",
      opacity: ".4",
      borderRadius: "3px",
    },
    minMinViewImgActive: {
      width: "80px",
      height: "80px",
      marginRight: "7px",
      cursor: "pointer",
      borderRadius: "3px",
    },

    minViewImg: {
      width: "80px",
      height: "80px",
      marginTop: "7px",
      cursor: "pointer",
      opacity: ".4",
      borderRadius: "3px",
    },
    minViewImgActive: {
      width: "80px",
      height: "80px",
      marginTop: "7px",
      cursor: "pointer",
      borderRadius: "3px",
    },

    bigViewImg: {
      padding: "10px",
      width: "240px",
      height: "475px",
      objectFit: "contain",
      objectPosition: "top",
      "@media (max-width: 500px)": {
        height: "325px",
      },
      [theme.breakpoints.down("xl")]: {
        width: "100%",
      },
    },
  })
);
