import { createStyles, fade, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => createStyles({
  textPagination: {
    fontSize: "1rem",
    "& span": {
      position: "relative",
      padding: ".15rem",
      "&:after": {
        position: "absolute",
        content: "''",
        width: "100%",
        height: ".1rem",
        background: fade(theme.palette.info.main, 0.71),      
        left: 0,
        bottom: 0,
      },
    },
  },
}));
