import Footer from "Components/Footer";
import Header from "Components/Header";
import Routes from "Routes";
import UserProvider from "Context/UserSesion/UserProvider";
import SearchProvider from "Context/Search/SearchProvider";
import CssBaseline from "@material-ui/core/CssBaseline";
import ShoppingProvider from "Context/Shopping/ShoppingContext";
import GeneralsProvider from "Context/Generals/GeneralsProvider";
import setYupLocale from "./Utils/Yup";
import "./App.css";

function App() {
  setYupLocale();
  return (
    <>
      <CssBaseline />
      <div className="App">
        <ShoppingProvider>
          <UserProvider>
            <SearchProvider>
              <GeneralsProvider>
                <Header />
                <Routes />
                <Footer />
              </GeneralsProvider>
            </SearchProvider>
          </UserProvider>
        </ShoppingProvider>
      </div>
    </>
  );
}

export default App;
