import {
  createStyles,
  fade,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    breadcrumb: {
      "& .MuiBreadcrumbs-separator": {
        marginRight: 11,
        marginLeft: 11,
        color: fade(theme.palette.secondary.main, 0.45),
        fontSize: ".75rem",
        lineHeight: "normal",
      },
      "& ol": {
        "& li:last-child": {
          "& $link": {
            color: fade(theme.palette.secondary.main, 0.8),
          },
        },
      },
    },
    link: {
      display: "flex",
      alignItems: "center",
      color: fade(theme.palette.secondary.main, 0.45),
      textTransform: "uppercase",
      fontSize: ".8rem",
      lineHeight: "normal",
      transition: ".5s all",
      "&:hover": {
        color: fade(theme.palette.secondary.main, 0.8),
      },
    },
    icon: {
      fontSize: "1.2rem",
      color: fade(theme.palette.secondary.main, 0.45),
      "&:hover": {
        color: fade(theme.palette.secondary.main, 0.8),
      },
    },
  })
);
