import { createContext } from "react";

export interface UserContextInterface {
  email?: string;
  name?: string;
  authenticated?: boolean;
  setUserState(authUser: UserContextInterface): void;
  setUserToken(userToken: string): void;
  token: string;
}

export const DefaultValues: UserContextInterface = {
  email: "",
  name: "",
  authenticated: false,
  setUserState: () => {},
  setUserToken: () => {},
  token: localStorage.getItem('kuna.stockservice-tokenAuth') || "",
};

export const UserContext = createContext<UserContextInterface>(DefaultValues);

UserContext.displayName = "UserContext";
