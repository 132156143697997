import { BackendHomepageImageType } from "../../Common/Types/BackendTypes";
import { HomepageImageType } from "../../Common/Types/HomepageImageType";


export const serializeHomepageImageToContext = (
  category: BackendHomepageImageType[]
): HomepageImageType[] => {
  const seriaLizeCategoryMap = category.map((item) => {
    const serializeCategory: HomepageImageType = {
      id: item.id,
      name: item.name,
      photoThumbnail: item.photo_thumbnail,
      nameEs: item.name_es,
      nameEn: item.name_en,
      url: item.url,
      attribute: item.attribute,
      attribute2: item.attribute_2,
      attribute3: item.attribute_3,
    };
    return serializeCategory;
  });
  return seriaLizeCategoryMap;
};
