import { createStyles, fade, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => createStyles({
  internalPage: {
    marginTop: 82.4,
    width: "98%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minHeight: "auto",
      marginTop: 100,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 110,
    },
  },
  left: {
    width: "18%",
    [theme.breakpoints.down("md")]: {
      width: "24%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: 0,
      paddingRight: 0,
      borderRight: "none",
    },
  },
  contLeft: {
    height: "calc(100% - 6.7rem)",
    padding: "4.8rem 0 3rem",
    borderRight: `.1rem solid ${fade(theme.palette.secondary.main, 0.55)}`,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
      paddingTop: 0,
      paddingBottom: 0,
      borderRight: "none",
      borderBottom: `.1rem solid ${fade(theme.palette.secondary.main, 0.55)}`,
    },
  },
  right: {
    width: "82%",
    padding: "2.5rem 2% 3.5rem 3%",
    [theme.breakpoints.down("md")]: {
      width: "76%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingTop: "1.2rem",
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  titlePage: {
    fontSize: "1.25rem",
    margin: "1.2rem 0",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      margin: "1.5rem 0 1rem",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "1rem 0 1.2rem",
    },
  },
  pagination: {
    justifyContent:"center",
    display:'flex',
  },
}));
