import {
    createStyles,
    makeStyles,
    Theme,
} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
    createStyles({
        menuSidebar:{
            position:'absolute',
            backgroundColor:"#fff",
            left:'0',
            top:'5%',
            width:'200px',
            paddingBottom:"25px",
            paddingTop:"25px",
            borderRadius: "4px",
            "& a":{
                color:"#3e3e3e"
            },
            "& >.MuiContainer-root":{
                padding:"0",
            },
            [theme.breakpoints.down("sm")]: {
                width:'400px',
            },
            [theme.breakpoints.down("xs")]: {
                width:'92vw',
            },
        },
        navColumn:{
            listStyle:"none",
            padding:"0",
            "& li":{
                margin:"4px 0",
                padding:"8px 24px",
                display:"flex",
                flexWrap:"wrap",
                justifyContent:"space-between",
                position:"relative",
                transition:"all .4s ease",
                "&:hover":{
                    background:"#f3f3f3",
                },
                "& ul":{
                    flex:1,
                    "& >li":{ 
                        transition:"all .4s ease",                        
                        "&:hover":{
                            background:"#e0e0e0!important"
                        }
                    }
                }
            },
            "& a":{
                fontSize:".93rem",
                textDecoration:"none",
                display:"block",
                textAlign:"left",
                flex:"80%",
            },
        },
        collapse:{
            display:"none",
        },
        active:{
            width:'100%',
            display:"block",
        },
        toggleMenu:{
            padding:0,
            color:theme.palette.primary.main,
            transform:"rotate(0deg)",
            transition:"all .5s ease",
        },
        rotate:{
            transform:"rotate(180deg)",
        },
        searchResponsive:{
            boxShadow: "0px 6px 20px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
            display: "flex",
            borderRadius: "30px",
            width: "90%",
            marginLeft: "20px",
            alignItems: "center",
            marginBottom: "20px",
            "& svg":{
                color:theme.palette.primary.main,
            }
        }

    })
);


