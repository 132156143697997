import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginBottom: ".5rem",
      "&>div": {
        position: "relative",
        paddingBottom: ".85rem",
      },
    },
    formControlSelect: {
      marginBottom: ".4rem",
      "&>div": {
        position: "relative",
        paddingBottom: ".85rem",
      },
    },
    btnSend: {
      background: theme.palette.primary.main,
      color: "#ffffff",
      width: "fit-content",
      borderRadius: "0",
      fontSize: 16,
      padding: "0.6rem 1rem",
      border: `2px solid ${theme.palette.primary.main}`,
      marginTop: "1.6rem",
      transition: "0.4s ease all",
      fontWeight: 500,
      letterSpacing: ".05rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: 15,
        marginTop: "1.2rem",
      },
      "&:hover": {
        backgroundColor: "#ffffff",
        color: theme.palette.primary.main,
      },
    },
    contFormRegister: {
      "& label + .MuiInput-formControl": {
        marginTop: 14,
      },
      "& MuiFormLabel-root": {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "100%",
      },
    },
    checkGroup: {
      marginBottom: "2rem",
      "& .MuiFormControlLabel-root": {
        marginLeft: "-7px",
      },
      "& .MuiSvgIcon-root": {
        fontSize: "1.2rem",
      },
      "& .MuiButtonBase-root": {
        padding: 5,
      },
    },
    labelCheckGroup: {
      paddingBottom: ".5rem",
    },
  })
);
