import {
  createStyles,
  fade,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    header: {
      width: "100%",
      transition: "background-color 0.5s ease-out",
      left: 0,
    },
    boxHeader: {
      padding: "1.6rem 0",
      height: "auto",
      minHeight: "auto",
      [theme.breakpoints.down("sm")]: {
        padding: "1rem 0",
      },
      [theme.breakpoints.down("xs")]: {
        padding: ".8rem 0",
      },
    },
    noScrollHeader: {
      backgroundColor: "transparent",
      "& $searchContent": {
        "& input": {
          [theme.breakpoints.down("sm")]: {
            background: "transparent",
          },
          "&::placeholder": {
            [theme.breakpoints.down("sm")]: {
              color: fade("#ffffff", 0.7),
            },
          },
        },
      },
      "& $searchIcon": {
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
    },
    scrollHeader: {
      backgroundColor: theme.palette.primary.main,
      zIndex: 2,
      boxShadow: `0 3px 6px ${fade(
        theme.palette.info.main,
        0.16
      )}, 0 3px 6px ${fade(theme.palette.info.main, 0.23)}`,
      "& $btnLogin": {
        "&:hover": {
          borderColor: "#ffffff",
          background: fade("#ffffff", 0.18),
        },
      },
      "& $btnRegister": {
        background: "#ffffff",
        borderColor: fade(theme.palette.primary.main, 0.4),
        "& span": {
          color: theme.palette.primary.main,
        },
        "&:hover": {
          borderColor: "#ffffff",
          background: fade("#ffffff", 0.18),
          "& span": {
            color: "#ffffff",
          },
        },
      },
      "& $searchContent": {
        "& input": {
          [theme.breakpoints.down("sm")]: {
            color: fade(theme.palette.info.main, 0.65),
          },
          "&:hover, &:focus": {
            borderColor: fade(theme.palette.primary.main, 0.4),
          },
        },
      },
      "& $iconDropdawn": {
        color: fade(theme.palette.info.main, 0.65),
      },
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    nav: {
      width: "98%",
      display: "flex",
      margin: "0 auto",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    contLogo: {
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      "& svg": {
        height: "1.2rem",
        width: "auto",
        [theme.breakpoints.down("md")]: {
          height: ".85rem",
        },
        [theme.breakpoints.down("xs")]: {
          height: ".65rem",
        },
      },
    },
    logo: {
      display: "flex",
    },
    line: {
      height: "1.6rem",
      background: "#ffffff",
      width: "0.05rem",
      margin: "0 1.2rem",
      [theme.breakpoints.down("sm")]: {
        height: "1.4rem",
        margin: "0 .6rem",
      },
    },
    right: {
      display: "flex",
      flexWrap: "wrap",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-between",
      },
      [theme.breakpoints.down("xs")]: {
        flexWrap:"initial",
      },
      "& form": {
        [theme.breakpoints.down("sm")]: {
          width: "30%",
        },
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
      },
    },
    searchInput: {
      position: "relative",
      display: "flex",
      alignItems: "center",
    },
    searchIcon: {
      position: "absolute",
      left: "0.8rem",
      display: "flex",
      "& svg": {
        width: "0.8rem",
        "& *": {
          fill: "#2b2b2b",
          [theme.breakpoints.down("sm")]: {
            fill: "#ffffff",
          },
        },
      },
    },
    searchContent: {
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "& input": {
        borderRadius: "14px",
        fontSize: "14px",
        padding: "0.35rem 0.5rem 0.35rem 2rem",
        overflow: "hidden",
        width: "15rem",
        color: "#000000",
        border: ".1rem solid #ffffff",
        transition: ".5s border-color",
        background: "#ffffff",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          paddingTop: ".4rem",
          paddingBottom: ".4rem",
        },
        "&::placeholder": {
          fontSize: "14px",
          color: fade(theme.palette.info.main, 0.64),
        },
        "&:hover, &:focus": {
          outline: "none",
          borderColor: theme.palette.primary.main,
        },
      },
    },
    searchBar:{
      borderRadius: "20px",
      display:"flex",
      height:"70%",
      paddingRight:"50px",
      marginTop:"5px",
      "& svg": {
        fontSize: "1.10rem",
      },
      "& input": {
        fontSize: "15px",
      },
    },
    btnLogin: {
      marginLeft: "1rem",
      background: "#ffffff",
      borderRadius: "14px",
      textTransform: "uppercase",
      padding: "0.35rem 1.2rem",
      position: "relative",
      overflow: "hidden",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      transition: ".5s background, border",
      border: ".1rem solid #ffffff",
      [theme.breakpoints.down("sm")]: {
        width: "33%",
        marginLeft: "0",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: ".6rem",
        width: "100%",
        paddingTop: ".58rem",
        paddingBottom: ".58rem",
      },
      "& span": {
        zIndex: 1,
        lineHeight: "100%",
        fontWeight: 600,
        color: fade(theme.palette.secondary.main, 0.6),
        fontSise: 12,
        transition: ".5s color",
      },
      "&:hover": {
        borderColor: theme.palette.primary.main,
        background: theme.palette.primary.main,
        "& span": {
          color: "#ffffff",
        },
      },
    },
    btnRegister: {
      marginLeft: "1rem",
      borderRadius: "14px",
      textTransform: "uppercase",
      padding: "0.35rem 1.2rem",
      position: "relative",
      overflow: "hidden",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      transition: ".5s background, border",
      border: `.1rem solid ${theme.palette.primary.main}`,
      background: theme.palette.primary.main,
      [theme.breakpoints.down("sm")]: {
        width: "33%",
        marginLeft: "0",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: ".6rem",
        width: "100%",
        paddingTop: ".58rem",
        paddingBottom: ".58rem",
      },
      "& span": {
        zIndex: 1,
        lineHeight: "100%",
        fontWeight: 600,
        color: "#ffffff",
        fontSise: 12,
        transition: ".5s color",
      },
      "&:hover": {
        borderColor: "#ffffff",
        background: "#ffffff",
        "& span": {
          color: theme.palette.primary.main,
        },
      },
    },
    textLogo: {
      color: "#ffffff",
      fontSize: "0.9rem",
      fontWeight: 500,
      letterSpacing: ".11rem",
      [theme.breakpoints.down("md")]: {
        letterSpacing: ".08rem",
        fontSize: "0.75rem",
      },
      [theme.breakpoints.down("sm")]: {
        letterSpacing: ".05rem",
        fontSize: "0.65rem",
        textAlign: "left",
      },
    },
    modalLogin: {
      "& .MuiDialog-paperWidthSm": {
        borderRadius: 0,
        [theme.breakpoints.down("sm")]: {
          margin: 20,
        },
      },
    },
    contTitleModalLogin: {
      padding: "1rem 1rem 1rem",
      textAlign: "center",
      textTransform: "uppercase",
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    titleModalLogin: {
      lineHeight: 1.1,
      color: theme.palette.info.main,
      fontFamily: "'Helvetica', sans-serif",
      fontSize: "19px",
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
    contModalLogin: {
      padding: 0,
    },
    buttonDropdawn: {
      padding: "8px",
    },
    iconDropdawn: {
      fontSize: ".9rem",
      color: "#ffffff",
    },
    language: {
      color: "#ffffff",
    },
    formControl: {
      marginLeft: theme.spacing(1.7),
      [theme.breakpoints.down("xs")]: {
        "& div": {
          fontSize: "12px",
        },
      },
    },
    select: {
      "&:before": {
        borderColor: "#ffffff",
      },
      "&:after": {
        borderColor: "#ffffff",
      },

      "& .MuiInputBase-input": {
        color: "#fff",
      },
    },
    icon: {
      fill: "#ffffff",
    },
    iconButtonClass: {
      color:"#fff",
      transition:"all .4s ease",
      [theme.breakpoints.down("xs")]: {
        "& svg": {
          fontSize:"1.25rem"
        },
        padding:"10px 5px"
      },
    },
    navMenu:{
      position:'relative'
    },
    iconMenu:{
      color:"#fff",
      marginRight: "25px",
      borderRadius: "4px",
      [theme.breakpoints.down("xs")]: {
        padding: "10px 5px",
        marginRight:"10px",
      },
    },
    iconMenuActive: {
      color:theme.palette.primary.main,
      borderRadius: "4px",
      background:"#fff",
      marginRight: "25px",
      "&:hover": {
        borderColor: "#ffffff",
        background: "#ffffff",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "10px 5px",
        marginRight:"10px",
      },
    },
    lengthProductsContainer: {
      position:'absolute',
      right:'6px',
      top:'6px',
      background: 'red',
      width:'18px',
      height:'18px',
      padding: '2px',
      borderRadius:'9999999px',
      fontSize:'.8rem',
      display: "flex",
      justifyContent:'center',
      alignItems: 'center',
      [theme.breakpoints.down("xs")]: {
        right:'0px',
        top:'2px',
        width:'15px',
        height:'15px',
      },
    },
    lengthProducts: {
      fontSize:'.8rem',
      color:'white'
    }
  })
);


