import i18n from "i18next";

import { Typography, Divider, Box } from "@material-ui/core";

import ColorList from "Components/ColorList";
import { DescriptionDetails } from "Common/Types/Products";
import { useTranslation } from "react-i18next";

import useStyles from "./styles";

export default function DescriptionProduct({
  product,
  changeColor,
  colorList,
}: DescriptionDetails) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.wrapper}>
      <Typography variant="h1" color="secondary" className={classes.title}>
        {i18n.language === "ES" ? product?.name_es : product?.name_en}
      </Typography>
      <Divider className={classes.divider} />

      <Typography
        variant="h5"
        color="secondary"
        className={classes.description}
      >
        {i18n.language === "ES" ? product?.composition_es : product?.composition_en} <br/>
        {product?.porini} <br/>
        {product?.code}
      </Typography>

      <Typography variant="h1" className={classes.title}>
        USD {product?.price}
      </Typography>
      <Typography className={classes.subTitle}>
        {t("Select color")}
      </Typography>
      <Box>
        <ColorList changeColor={changeColor} colorListDetails={colorList} />
      </Box>
    </Box>
  );
}
