import { CategoryType } from "../../Common/Types/Categories";

import { BackendCategoryType } from "../../Common/Types/BackendTypes";

export const serializeAtributesToContext = (
  category: BackendCategoryType[]
): CategoryType[] => {
  const seriaLizeCategoryMap = category.map((item) => {
    const serializeCategory: CategoryType = {
      id: item.id,
      name: item.name,
      photoThumbnail: item.photo_thumbnail,
      isShownOnHome: item.is_shown_on_home,
      nameEs: item.name_es,
      nameEn: item.name_en,
    };
    return serializeCategory;
  });
  return seriaLizeCategoryMap;
};
