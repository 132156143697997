import { Children } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Hidden,
  Card,
  CardContent,
} from "@material-ui/core";

import {ColorModel, ItemQuotation, ShoppingPropsModel, ToShoppingModel} from "Common/Types/Products";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";

const headerTable = [
  "ID",
  "Porini Code",
  "Model Code",
  "Model Name",
  "Color Code",
  "Color Name",
  "Size",
  "FCA Price",
  "Quantity",
];


export interface QuotationDetailProduct {
  products: ItemQuotation[];
}

export default function ProductQuotation({ products }: QuotationDetailProduct) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Hidden only={["sm", "xs"]}>
        {products.length && (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className={classes.tableHead}>
                    {Children.toArray(
                      headerTable.map((title) => (
                        <TableCell>{t(title)}</TableCell>
                      ))
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Children.toArray(
                    products.map((item) => (
                      <TableRow className={classes.tableBody}>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>{item.colour_code}</TableCell>
                        <TableCell>{item.model_code}</TableCell>
                        <TableCell>{item.model_name}</TableCell>
                        <TableCell>{item.colour_code}</TableCell>
                        <TableCell>{item.colour_name}</TableCell>
                        <TableCell>{item.size_repr}</TableCell>
                        <TableCell>USD$ {item.price}</TableCell>
                        <TableCell>{item.amount}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        {products.length && (
          <>
            {Children.toArray(
              products.map((item) => (
                <Card className={classes.cardContainer}>
                  <div className={classes.detailsCard}>
                    <CardContent className={classes.contentCard}>
                      <Typography className={classes.titleCard}>
                        {" "}
                        {item.model_name}{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        {t("ID")}: {item.id}
                        <br />
                        {t("Porini Code")}: {item.colour_code}
                        <br />
                        {t("Model Code")}: {item.model_code}
                        <br />
                        {t("Color Code")}: {item.colour_code}
                        <br />
                        {t("Color Name")}: {item.colour_name}
                        <br />
                        {t("Size")}: {item.size_repr} <br />
                        {t("FCA Price")}: USD$ {item.price}
                        <br />
                        {t("Quantity")}: {item.amount}{" "}
                      </Typography>
                    </CardContent>
                  </div>
                </Card>
              ))
            )}
          </>
        )}
      </Hidden>
    </>
  );
}
