import { useState } from "react";
import { Img } from "react-image";
import { useEffect } from "react";

import { Hidden, Box } from "@material-ui/core";

import { ImageViewProps } from "Common/Types/Products";
import useStyles from "./styles";

interface ImgActiveModel {
  id: number;
  photoThumbnail: string;
  description: string;
  active: boolean;
  photo: string;
}

export default function ImageViewer({ imageList }: ImageViewProps) {
  const classes = useStyles();
  const [dataListImg, setDataListImg] = useState(imageList);
  const [ImgActive, setImgActive] = useState<ImgActiveModel>(dataListImg[0]);

  // Activar segun id
  const handleActiveImage = (id: number) => {
    const aux = [...dataListImg];
    aux.map((item) => {
      if (id === item.id) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    // Filtrar para sacar el que este activo
    const filterActive = aux.filter(
      (item: ImgActiveModel) => item.active === true
    );
    setImgActive(filterActive.length ? filterActive[0] : dataListImg[0]);
    setDataListImg(aux);
  };

  return (
    <div className={classes.wrapper}>
      {/* Para mostrar solo en resoluciones Pequeñas */}
      <Hidden only={["md", "lg", "xl"]}>
        <Box className={classes.minViewContainer}>
          <Box>
            <img
              src={
                dataListImg.length > 0
                  ? ImgActive.photo
                  : "https://plantillasdememes.com/img/plantillas/imagen-no-disponible01601774755.jpg"
              }
              alt="exit"
              className={classes.bigViewImg}
            />
          </Box>
          <Box className={classes.minViewList}>
            {dataListImg.map((item, index) => (
              <img
                key={index}
                src={item.photoThumbnail}
                alt={item.description}
                className={
                  item.active
                    ? classes.minMinViewImgActive
                    : classes.minMinViewImg
                }
                onClick={() => handleActiveImage(item.id)}
              />
            ))}
          </Box>
        </Box>
      </Hidden>
      {/* Para mostrar solo en resoluciones Grandes */}
      <Hidden only={["sm", "xs"]}>
        <Box className={classes.bigViewContainer}>
          <Box className={classes.bigViewList}>
            {dataListImg.map((item, index) => (
              <img
                key={index}
                src={item.photoThumbnail}
                alt={item.description}
                className={
                  item.active ? classes.minViewImgActive : classes.minViewImg
                }
                onClick={() => handleActiveImage(item.id)}
              />
            ))}
          </Box>
          <Box>
            <img
              src={
                dataListImg.length > 0
                  ? ImgActive.photo
                  : "https://plantillasdememes.com/img/plantillas/imagen-no-disponible01601774755.jpg"
              }
              alt="exit"
              className={classes.bigViewImg}
            />
          </Box>
        </Box>
      </Hidden>
    </div>
  );
}
