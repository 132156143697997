import { createStyles, fade, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => createStyles({
  chip: {
    borderRadius: 0,
    width: "100%",
    justifyContent: "space-between",
    height: 24,
    background: "#DDDCD4",
    color: fade(theme.palette.info.main, 0.6),
    "& span": {
      paddingLeft: 9,
      textTransform: "uppercase",
      fontSize: 12,
    },
    "& .MuiChip-deleteIcon": {
      width: "18px",
      height: "18px",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  titleSectionFiltro: {
    fontSize: "1.2rem",
    textTransform: "uppercase",
    lineHeight: "normal",
    fontWeight: 500,
    marginBottom: ".5rem",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.05rem",
    },
  },
  btnClean: {
    color: theme.palette.primary.main,
    textTransform: "capitalize",
    cursor: "pointer",
    fontSize: ".85rem",
    marginBottom: "1rem",
    transition: ".5 all",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  contFilter: {
    paddingRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
    },
  },
  divider: {
    height: ".1rem",
    width: "70%",
    margin: "1.6rem auto",
    backgroundColor: fade(theme.palette.info.main, 0.12),
  },
  contChips: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: 0,
    margin: 0,
    width: "100%",
    marginBottom: ".8rem",
    marginLeft: "-.2rem",
    "& li": {
      padding: " .2rem",
      maxWidth: "100%",
    },
  },
  itemAccordion: {
    boxShadow: "none",
    borderBottom: `.1rem solid ${fade(theme.palette.info.main, 0.12)}`,
    "&.Mui-expanded": {
      marginBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  },
  titleAccordion: {
    padding: 0,
    minHeight: 45,
    "& .MuiAccordionSummary-content": {
      margin: "10px 0",
    },
    "&.Mui-expanded": {
      minHeight: "auto",
    },
    "& .MuiIconButton-root": {
      marginRight: 0,
      "& svg": {
        fontSize: 14,
      },
    },
  },
  bodyAccordion: {
    padding: "5px 20px 20px",
  },
  GroupRadioButton: {
    width: "100%",
    "& label": {
      width: "100%",
      justifyContent: "space-between",
      marginLeft: 0,
      marginRight: -2,
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.3rem",
    },
    "& .MuiButtonBase-root": {
      padding: 6,
      marginRight: -8,
    },
  },
  headerCollapse: {
    display: "flex",
    alignItems: "center",
    padding: "1.5rem 0",
    [theme.breakpoints.down("xs")]: {
      padding: "1.2rem 0",
    },
  },
  iconFilter: {
    fontSize: "1.65rem",
    marginRight: ".8rem",
  },
  count: {
    marginLeft: "1rem",
    background: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    minWidth: "1.6rem",
    minHeight: "1.6rem",
    borderRadius: 15,
    padding: ".301rem .2rem",
    justifyContent: "center",
    lineHeight: "100%",
    "& span": {
      color: "#ffffff",
      fontSize: ".9rem",
    },
  },
  contAccordion: {
    "& div:last-child": {
      border: "none",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "2rem",
    },
  },
}));