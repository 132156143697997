import {
  Box,
  Container,
  Hidden,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Pagination from '@material-ui/lab/Pagination';
import BreadCrumbs from "Components/BreadCrumbs";
import Filter from "Components/Filter";
import GridProducts from "Components/GridProducts";
import { SearchContext } from "Context/Search/SearchContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import api from "Utils/AxiosConfig";
import { serializeProductToContext } from "Utils/Serializers/serializeProductToContext";
import useStyles from "./styles";

export default function SearchPage() {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const shearchState = useContext(SearchContext);
  const { setSearchFilter } = useContext(SearchContext);

  const query = useQuery(
    [
      "shearchState",
      {
        search: shearchState.productName,
        attributes: shearchState.idAttributes,
        attributes2: shearchState.idAttributes2,
        attributes3: shearchState.idAttributes3,
        category: shearchState.idCategories,
        ordering: shearchState.ordenBy,
        page: shearchState.page,
      },
    ],
    async () => 
    api.get( `api/products/products`, {
      params : {
        search: shearchState.productName,
        attributes__id__in: shearchState.idAttributes,
        attributes_2__id__in: shearchState.idAttributes2,
        attributes_3__id__in: shearchState.idAttributes3,
        category: shearchState.idCategories,
        ordering: shearchState.ordenBy,
        page: shearchState.page,
      }
    })
  );

  const handleChange = (event: any, value: any) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    var aux = { ...shearchState };
    aux.page = value
    setSearchFilter(aux)
  };

  return (
    <Box className={classes.internalPage}>
      <Container maxWidth="xl" disableGutters={matches ? true : false}>
        <Box display="flex" flexWrap="wrap">
          <Box className={classes.left}>
            <Box className={classes.contLeft}>
              <Container disableGutters={matches ? false : true}>
                <Filter />
              </Container>
            </Box>
          </Box>
          <Box className={classes.right}>
            <Container disableGutters={matches ? false : true}>
              <BreadCrumbs title={t("Searching")} />
              <Hidden only={["xs"]}>
                <Typography
                  variant="h3"
                  align="center"
                  color="primary"
                  className={classes.titlePage}
                >
                  {t("SEARCH RESULTS")}
                </Typography>
              </Hidden>
              {query.isLoading ? (
                <>
                  <Typography
                    variant="h3"
                    align="center"
                    color="primary"
                    className={classes.titlePage}
                  >
                    {t("LOADING...")}
                  </Typography>
                </>
              ) : (
                <>
                  <GridProducts
                    data={serializeProductToContext(
                      query.data?.data.results || []
                    )}
                  />  
                  <div className={classes.pagination}>
                    <Pagination
                      count={parseInt((query.data?.data.count / 20).toFixed(0))}
                      onChange={handleChange}
                      page={shearchState.page}
                      shape="rounded"
                      size="large"
                      color="primary"
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </>
              )}
            </Container>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
