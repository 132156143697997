import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";

import RegisterForm from "Components/RegisterForm";
import useStyles from "./styles";

interface IRegisterModalProps {
  openModal: boolean;
  closeModal(): void;
}

export default function ModalRegistro({
  openModal,
  closeModal,
}: IRegisterModalProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      maxWidth="md"
      open={openModal}
      onClose={closeModal}
      aria-labelledby="form-dialog-title"
      className={classes.modalRegister}
    >
      <DialogTitle
        id="form-dialog-title"
        className={classes.contTitleModalRegister}
      >
        {t("Register")}
      </DialogTitle>
      <DialogContent className={classes.contModalRegister}>
        <RegisterForm closeModal={closeModal} />
      </DialogContent>
    </Dialog>
  );
}
