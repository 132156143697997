// Import core modules
import React, {useState} from "react";
import Axios from "axios";
import { useMutation } from "react-query";
import * as Yup from "yup";
import {useHistory } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

// Import Material Ui / Formik Related
import { Box, FormControl,Button } from "@material-ui/core";
import { TextField } from "formik-material-ui";

// Import Locals
import ConfirmModal from 'Components/ConfirmModal';
import useStyles from "./styles";

const INITIAL_VALUES = {
    email: ""
};

interface Body {
    email: string
}



export default function PasswordResetForm() {
    const classes = useStyles();
    const [showModal, setShowModal] = useState(false);
    const [showModalError, setShowModalError] = useState(false);
    const history = useHistory();
    const { t }= useTranslation();

    const apiBase: string = process.env.REACT_APP_API_LINK as string;

    const mutation = useMutation(
        (data: Body) => Axios.post(apiBase + "api/users/password-reset/", data),
        {
            onError: (error) => {
                setShowModalError(true)
            },
            onSuccess: () => {
                setShowModal(true);
            },
        }
    );
    const validationSchema = () =>
    Yup.object().shape({
        email: Yup.string().required().email(),
    });

    function Submit(email: string) {
        const body: Body = {
            email
        };
        mutation.mutate(body);
    }
    return (
      <>
        <Formik
          initialValues={INITIAL_VALUES}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            Submit(values.email);
            resetForm({ values: INITIAL_VALUES });
            setSubmitting(false);
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <FormControl fullWidth className={classes.formControl}>
                <Field
                  component={TextField}
                  fullWidth
                  name="email"
                  type="email"
                  label={t("Email")}
                />
              </FormControl>

              <Box display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  className={classes.btnSend}
                >
                  {t("Send Instructions")}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
        <ConfirmModal
          title={t("Success")}
          openModal={showModal}
          closeModal={() => {
            history.push("/login");
            setShowModal(false);
          }}
          message={t(
            "We have sent a password recovery instructions to your email"
          )}
        />
        <ConfirmModal
          title={t("Error")}
          openModal={showModalError}
          closeModal={() => setShowModalError(false)}
          message={t(
            "There is no active user associated with this e-mail address or the password can not be changed"
          )}
        />
      </>
    );
}