// Import core modules
import React, {useState, MouseEvent} from "react";
import Axios from "axios";
import {useHistory, useLocation} from 'react-router-dom';
import { Field, Form, Formik } from "formik";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

// Import Material Ui / Formik Related
import { Box, FormControl,Button, InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { TextField } from "formik-material-ui";

// Import Locals
import ConfirmModal from 'Components/ConfirmModal';
import useStyles from "./styles";

const INITIAL_VALUES = {
    password: "",
};

interface Body {
    password: string,
    token: string,
}

interface PasswordForm{
    token:string,
}


export default function PasswordResetForm({token}:PasswordForm) {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const { t } = useTranslation();

    const apiBase: string = process.env.REACT_APP_API_LINK as string;
    const history = useHistory();

    const mutation = useMutation(
        (data: Body) => Axios.post(apiBase + "api/users/password-reset/confirm/", data),
        {
            onError: (error) => {
                setShowModal(true)
            },
            onSuccess: () => {
                history.push('/login')
            },
        }
    );
    const validationSchema = () =>
    Yup.object().shape({
        password: Yup.string()
        .required()
        .min(8, t("The password must have a minimum of 8 characters.")),
    });
    function Submit(password: string) {
      const body: Body = {
        token,
        password: password,
      };
      mutation.mutate(body);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: MouseEvent) => {
        event.preventDefault();
    };
    
    return ( 
        <>
            <Formik
                initialValues={INITIAL_VALUES}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitting(false);
                    Submit(values.password) 
                    resetForm({ values: INITIAL_VALUES });
                }}
            >
                {({ submitForm, isSubmitting }) => (
                <Form>
                    <FormControl fullWidth className={classes.formControl}>
                        <Field
                            component={TextField}
                            fullWidth
                            type={showPassword ? 'text' : 'password'}
                            label={t("Password")}
                            name="password"
                        />
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    </FormControl>

                    <Box display="flex" justifyContent="center">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        className={classes.btnSend}
                    >
                        {t('Reset password')}
                    </Button>
                    </Box>
                </Form>
                )}
            </Formik>
            <ConfirmModal  
                title={t("Error")}
                openModal={showModal} 
                closeModal={() => setShowModal(false)} 
                message={t("Your token has expired or your password has recently been updated")}
            />
        </>
    );
}