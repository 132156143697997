import * as React from "react";
import { Route, Switch } from "react-router-dom";

import HomePage from "Views/Home";
import SearchPage from "Views/Search";
import LoginPage from "Views/Login";
import RegisterPage from "Views/Register";
import PasswordResetPage from "Views/PasswordReset";
import ActivatePage from "Views/Activate";
import Shopping from "Views/Shopping";
import TermsConditionsPage from "Views/TermsConditions";
import PrivacyPolicyPage from "Views/PrivacyPolicy";
import QuotationPage from "Views/QuotationConfirmation";

const slugUrl = ":slug([a-z-0-9]+)/:id([0-9]+)/";
export const baseUrl = "/";
export const searchUrl = `${baseUrl}search/`;
export const loginUrl = `${baseUrl}login/`;
export const registerUrl = `${baseUrl}register/`;
export const resetUrl = `${baseUrl}resetPassword/`;
export const resetPasswordUrl = `${baseUrl}resetNewPassword/:token`;
export const activationUrl = `${baseUrl}activation/:uuid/:token`;
export const shoppingUrl = `${baseUrl}shopping/`;
export const termsConditionsUrl = `${baseUrl}termsConditions/`;
export const privacyPolicysUrl = `${baseUrl}privacyPolicy/`;
export const quotationUrl = `${baseUrl}quotation/:quotationId`;

export const Routes: React.FC = () => (
  <Switch>
    <Route exact path={baseUrl} component={HomePage} />
    <Route path={searchUrl} component={SearchPage} />
    <Route path={loginUrl} component={LoginPage} />
    <Route path={registerUrl} component={RegisterPage} />
    <Route path={resetUrl} component={PasswordResetPage} />
    <Route path={resetPasswordUrl} children={<PasswordResetPage />} />
    <Route path={activationUrl} component={ActivatePage} />
    <Route path={shoppingUrl} component={Shopping} />
    <Route path={termsConditionsUrl} component={TermsConditionsPage} />
    <Route path={privacyPolicysUrl} component={PrivacyPolicyPage} />
    <Route path={quotationUrl} component={QuotationPage} />
  </Switch>
);

export default Routes;
