/* tslint:disable */

import { createMuiTheme, fade } from '@material-ui/core/styles';

const PRIMARY_COLOR = '#C0B021';
const TEXT_COLOR = "#424241";
const SECONDARY_COLOR = '#444444';
const INPUT_COLOR = "#3f3f3f"

export default createMuiTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    text: {
      primary: INPUT_COLOR,
    },
    info: {
      main: TEXT_COLOR,
    },
    background: {
      default: "#ffffff",
    },
  },
  typography: {
    fontFamily: ['"Helvetica"', 'sans-serif'].join(','),
    body1: {
      fontSize: 15,
      lineHeight: 1.3,
      color: fade(TEXT_COLOR, 0.7),
    },
    body2: {
      fontSize: 13,
    }
  },

  // Overrides
  overrides: {
    MuiButton: {
      root: {
        fontFamily: ['"Helvetica"', 'sans-serif'].join(','),
        borderRadius: 0,
        fontSize: '0.875rem',
        fontWeight: 700,
        padding: '0 50px',
        lineHeight: 'normal',
        '@media (max-width: 960px)': {
          padding: '0 20px',
        },
      },
      containedPrimary: {
        backgroundColor: "#9d9d9c",
      },
      containedSecondary: {
        color: '#ffffff',
      },
      outlinedSecondary: {
        color: '#ffffff',
        border: '1px solid currentColor',
        transition: 'border-width ease-in-out',
        '&:hover': {
          border: '2px solid currentColor',
          backgroundColor: 'rgba(255, 255, 255, 0.1)'
        }
      },
      outlined: {
        transition: 'border-width ease-in-out',
        '&:hover': {
          borderWidth: 2
        }
      },
      outlinedPrimary: {
        transition: 'border-width ease-in-out',
        borderColor: "#707070",
        '&:hover': {
          borderWidth: 2
        },
      }
    },
    MuiFormControlLabel: {
      root: {
        marginRight: 0
      }
    },
    MuiFormLabel: {
      root: {
        fontFamily: ['"Helvetica"', 'sans-serif'].join(','),
        fontWeight: 500,
        color: fade(INPUT_COLOR, 0.6),
      }
    },
    MuiFormHelperText: {
      root: {
        marginTop: 0,
        position: "absolute",
        bottom: 0,
        lineHeight: "100%",
        fontSize: ".68rem",
        fontFamily: ['"Helvetica"', 'sans-serif'].join(','),
      }
    },
    MuiSelect: {
      root: {
        borderRadius: 0
      },
      outlined: {
        borderRadius: 0
      }
    },
    MuiTabs: {
      root: {
        height: "auto",
      },
      indicator: {
        backgroundColor: PRIMARY_COLOR,
        height: "0.25rem",
      }
    },

    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        '&:hover $notchedOutline': {
          borderWidth: 2
        },
      },
      notchedOutline: {
        borderColor: 'inherit',
      }
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 16px) scale(1)'
      }
    },
    MuiMenuItem: {
      root: {
        fontFamily: ['"Helvetica"', 'sans-serif'].join(','),
      }
    },
    MuiInputBase: {
      input: {
        paddingBottom: 7,
        paddingTop: 7,
        fontFamily: ['"Helvetica"', 'sans-serif'].join(','),
        color: INPUT_COLOR,
        fontSize: 16,
        lineHeight: "normal",
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: '#ffffff',
        borderLeft: '8px solid #C4B039',
        borderRadius: 0,
        color: PRIMARY_COLOR,
      }
    },
    // @ts-ignore
    MuiToggleButton: {
      root: {
        color: PRIMARY_COLOR,
        border: `1px solid ${PRIMARY_COLOR}`,
        borderRadius: 0,
        minWidth: 100,
        fontWeight: 'bold',
        '&$selected': {
          color: '#ffffff',
          backgroundColor: PRIMARY_COLOR,
          '&:hover': {
            backgroundColor: 'rgba(112, 111, 111, 0.8)',
          },
        },
        '@media (min-width:960px)': {
          minWidth: 200,
        },
      },
    },
    MuiPaginationItem: {
      root: {
        margin: 0
      },
      page: {
        '&$selected': {
          backgroundColor: 'initial',
          fontWeight: 700,

          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
          }
        }
      }
    },
    MuiAutocomplete: {
      root: {
        '& [class*="MuiInput-underline"]': {
          marginTop: 16
        }
      },
      inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
          '& $input': {
            padding: '7.5px 4px 3.5px',
          }
        }
      }
    },
    MuiDialog: {
      scrollPaper: {
        paddingRight: 17,
        '@media (max-width:960px)': {
          paddingRight: 0,
        },
      }
    },
   
  }
});
