import { ColorModel, imageList, StockModel } from "Common/Types/Products";
import {
  BackendColourPhotoSet,
  BackendProductDetailsColor,
  BackendVariantSet,
} from "Common/Types/BackendTypes";

export const serializeProductToContext = (
  productDetailColor: BackendProductDetailsColor[]
): ColorModel[] => {
  const seriaLizeProductMap = productDetailColor.map((item, index) => {
    const serializeProduct: ColorModel = {
      id: item.id,
      color: item.code,
      active: index === 0 ? true : false,
      imageList: serializeColourPhotoSet(item.colourphoto_set),
      stock: serializeStockSet(item.variant_set),
      toCard: "",
    };
    return serializeProduct;
  });
  return seriaLizeProductMap;
};

const serializeColourPhotoSet = (
  colourphotoSet: BackendColourPhotoSet[]
): imageList[] => {
  const serializeColurSet = colourphotoSet.map((item, index) => {
    return {
      id: index,
      photoThumbnail: item.photo_thumbnail,
      photo: item.photo,
      description: "",
      active: index === 0 ? true : false,
    };
  });
  return serializeColurSet;
};

const serializeStockSet = (stockSet: BackendVariantSet[]): StockModel[] => {
  const serializeStockSet = stockSet.map((item) => {
    var avaliableStock = 0;
    item.stock_set.map((stock) =>{
      avaliableStock += stock.amount
    })
    return {
      avaliableStock: avaliableStock,
      myOrder: 0,
      size: item.size,
      sizeId: item.size_id,
    };
  });
  return serializeStockSet;
};
