import Axios from "axios";

const apiBase: string = process.env.REACT_APP_API_LINK as string;

const defaultOptions = {
  baseURL: apiBase,
}

const api = Axios.create(defaultOptions)

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem("kuna.stockservice-tokenAuth");  
  config.headers.Authorization = token ? `Token ${token}` : '';
  return config;
});

export default api;