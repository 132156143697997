import {useContext, useState} from "react";

import {
  Box,
  Container,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import BreadCrumbs from "Components/BreadCrumbs";
import ProductQuotation from "Components/ProductQuotation";
import { shoppingContext } from "Context/Shopping/ShoppingContext";
import { useTranslation } from "react-i18next";
import {useQuery} from "react-query";
import {useParams} from "react-router-dom";
import {ProductTypeDetails, QuotationDetail} from "../../Common/Types/Products";
import api from "../../Utils/AxiosConfig";
import {serializeProductToContext} from "../../Utils/Serializers/serializeProductDetailsColor";
import useStyles from "./styles";
import { UserContext } from "Context/UserSesion/UserContext";

interface QuotationID{
    quotationId:string
}

export default function QuotationPage() {
  const {quotationId} = useParams<QuotationID>();
  const [quotationData, setQuotationData] = useState<QuotationDetail>();
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { shopping } = useContext(shoppingContext); 
  const { name, email } = useContext(UserContext);

  useQuery(
    ["id", quotationId],
    async () => api.get(`api/quotations/quotations/${quotationId}/`),
    {
      onSuccess: (res) => {
        const data = res.data;
        setQuotationData(data);
        // setProductDetail(data);
        // const serial = serializeProductToContext(data.colour_set);
        // setColorList(serial);
      },
    }
  );

  return (
    <Box className={classes.internalPage}>
      <Container maxWidth="xl" disableGutters={matches ? true : false}>
        <Box display="flex" flexWrap="wrap">
          <Box className={classes.right}>
            <Container disableGutters={matches ? false : true}>
              <BreadCrumbs title={t("QUOTATION CONFIRMATION")} />
                <Typography
                  variant="h3"
                  align="center"
                  color="primary"
                  className={classes.titlePage}
                >
                  {t("Thank you for your order request")}
                </Typography>

                <Typography
                  variant="h6"
                  align="center"
                  color="secondary"
                  className={classes.description}
                >
                  {t("A confirmation of your request will be sent within the next 48 business hours")}
                </Typography>

                <Typography
                  variant="h6"
                  align="left"
                  color="secondary"
                  className={classes.userData}
                >
                  {t("Request Order")}: {quotationData?.id} <br/>
                  {t("Date and hour")}: {quotationData?.date}<br/>
                  {t("Company")}: {quotationData?.user.user.name} <br/>
                  {t("Billing address")}: {quotationData?.user.address} <br/>
                  {t("Order detail")}: <br/>
                </Typography>
                
              <ProductQuotation products={quotationData?.item_set || []} />
              <Typography
                  variant="h6"
                  align="left"
                  color="secondary"
                  className={classes.footerTitle}
                >
                  {t("For additional information")}: <br/>
                </Typography>

                <Typography
                  variant="h6"
                  align="left"
                  color="secondary"
                  className={classes.footerData}
                >
                  {t("Phone Number")}: +51 54 603000 anexo 444<br/>
                  {t("E-mail")}: purquiza@incalpaca.com<br/>
                  {t("Customer service hours")}: 8:00 hrs {t("to")} 16:00 hrs (GMT-5)<br/>
                </Typography>

            </Container>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
