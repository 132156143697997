import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => createStyles({
  form:{
    marginRight: 20,
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  errorMessage: {
    fontSize: ".68rem",
    color: "#F44336",
    marginTop: -15,
    bottom: 7,
    position: "absolute",
    textAlign: "left",
  },
})
);
