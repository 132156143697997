// Import core modules
import React, {useState, useEffect} from "react";
import Axios from "axios";
import {Link, useHistory, useParams} from 'react-router-dom';
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";

// Import Material Ui / Formik Related
import { Container, Grid, Typography, Button } from "@material-ui/core";


// Import Locals
import useStyles from "./styles";


interface Activation {
    uuid:string,
    token:string,
}

export default function Activate() {
    const classes = useStyles();
    const { t } = useTranslation();
    const {uuid, token} = useParams<Activation>();
    const [error, setError] = useState(false);
    const history = useHistory();
    const apiBase: string = process.env.REACT_APP_API_LINK as string;

    const mutation = useMutation(
        (data: Activation) => Axios.post(apiBase + "api/users/activation/", data),
        {
            onError: (error) => {
                setError(true)
                console.error("Error: ", error);
            },
            onSuccess: () => {
                setError(false);
            },
        }
    );

    useEffect(()=>{
        const activation: Activation = {
            uuid:uuid,
            token:token
        }
        mutation.mutate(activation);
    }, [])

    return (
        <>
            <Grid container className={classes.contBanner}>
                <Container maxWidth="md">
                    {!error ? (
                        <>
                            <Typography variant="h1" component="h2">
                                {t('Your account has been activated')}
                            </Typography>
                            <div>
                                <Button href="/login" className={classes.btnSend}>{t('Sign in')}</Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <Typography variant="h1" component="h2">
                                {t('Invalid token account')}
                            </Typography>
                            <Typography component="p">{t('Your account was activated before.')}</Typography>
                            <div>
                                <Button href="/login" className={classes.btnSend}>{t('Sign in')}</Button>
                            </div>
                        </>
                    )}                    
                </Container>
            </Grid>
        </>
    );
}
