import Axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";

import {
  Box,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { CheckboxWithLabel, TextField } from "formik-material-ui";

import CountryType from "Common/Types/CountryType";
import ConfirmModal from "Components/ConfirmModal";
import { CountriesAutocomplete } from "Components/CountriesAutocomplete";
import { consoleTestResultHandler } from "tslint/lib/test";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import {countryList} from "../../Utils/countries";

const INITIAL_VALUES = {
  name: "",
  representative: "",
  email: "",
  password: "",
  web: "",
  country: "",
  comments: "",
};

interface BodyRegister {
  user: {
    name: string;
    email: string;
    password: string;
  };
  representative: string;
  website: string;
  country: string;
  interested_business: string[];
  comentaries: string;
}

interface IRegisterFormProps {
  closeModal(): void;
}

const validationSchema = () =>
  Yup.object({
    name: Yup.string().required(),
    representative: Yup.string().required(),
    email: Yup.string().required().email(),
    password: Yup.string().required().min(8, "El mínimo de caracteres es 8"),
    country: Yup.string().required(),
  });

export default function RegisterForm({ closeModal }: IRegisterFormProps) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = useTranslation();

  const apiBase: string = process.env.REACT_APP_API_LINK as string;
  const [repeated, setRepeated] = useState(false);
  const [open, setOpen] = useState(false);

  const [businessType, setBusinessType] = useState({
    franchises: true,
    wholesaler: false,
    b2b: false,
  });

  const { franchises, wholesaler, b2b } = businessType;
  const error = [franchises, wholesaler, b2b].filter((v) => v).length === 0;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const countries: CountryType[] = countryList;
  const handleClose = () => {
    setOpen(false);
    closeModal();
  };

  const business: string[] = [];

  const mutation = useMutation(
    (data: BodyRegister) => Axios.post(apiBase + "api/users/users/", data),
    {
      onError(error) {
        setRepeated(true);
        console.error(error);
      },
      onSuccess() {
        handleClickOpen();
      },
    }
  );

  const addBusiness = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBusinessType({
      ...businessType,
      [event.target.name]: event.target.checked,
    });
  };

  const Submit = (
    name: string,
    email: string,
    password: string,
    representative: string,
    website: string,
    country: string,
    comentaries: string
  ) => {
    const countryCode: CountryType = country as any;

    if (franchises === true) {
      business.push("F");
    }
    if (wholesaler === true) {
      business.push("W");
    }
    if (b2b === true) {
      business.push("B");
    }

    if (business.length > 0) {
      const bodyRegister: BodyRegister = {
        user: {
          name,
          email,
          password,
        },
        representative,
        website,
        country: countryCode.code,
        interested_business: business,
        comentaries,
      };
      mutation.mutate(bodyRegister);
    }
  };

  return (
    <div className={classes.contFormRegister}>
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          Submit(
            values.name,
            values.email,
            values.password,
            values.representative,
            values.web,
            values.country,
            values.comments
          );
        }}
      >
        {({ submitForm, isSubmitting, touched, values }) => (
          <Form>
            <div>
              <Grid container spacing={matches ? 7 : 2}>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <Field
                      component={TextField}
                      name="name"
                      type="text"
                      label={t("Name (*)")}
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.formControl}>
                    <Field
                      component={TextField}
                      name="representative"
                      type="text"
                      label={t("Name of the representative (*)")}
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.formControl}>
                    <Field
                      component={TextField}
                      name="email"
                      type="email"
                      label={t("Email (*)")}
                      error={(touched.email && values.email === "") || repeated}
                      helperText={repeated ? t("Email already exists") : ""}
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.formControl}>
                    <Field
                      component={TextField}
                      name="password"
                      type="password"
                      label={t("Password (*)")}
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.formControl}>
                    <Field
                      component={TextField}
                      name="web"
                      type="url"
                      label={t("Web page")}
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.formControl}>
                    <CountriesAutocomplete
                      countries={countries}
                      touched={touched}
                      values={values.country}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl
                    required
                    error={error}
                    fullWidth
                    className={classes.checkGroup}
                  >
                    <FormLabel
                      component="legend"
                      className={classes.labelCheckGroup}
                    >
                      {t("Interested businesses")}
                    </FormLabel>
                    <FormGroup>
                      <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="franchises"
                        color="primary"
                        onChange={addBusiness}
                        indeterminate={false}
                        checked={franchises}
                        Label={{ label: t("Franchises") }}
                      />
                      <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="wholesaler"
                        color="primary"
                        onChange={addBusiness}
                        checked={wholesaler}
                        indeterminate={false}
                        Label={{ label: t("Wholesalers") }}
                      />
                      <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="b2b"
                        color="primary"
                        onChange={addBusiness}
                        checked={b2b}
                        indeterminate={false}
                        Label={{ label: "B2B" }}
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl fullWidth className={classes.formControl}>
                    <Field
                      component={TextField}
                      name="comments"
                      label={t("Additional comments")}
                      variant="outlined"
                      multiline
                      rows={6}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
                className={classes.btnSend}
              >
                Registrarse
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <ConfirmModal
        title={t("Success")}
        openModal={open}
        closeModal={handleClose}
        message={t("You have successfully registered")}
      />
    </div>
  );
}
