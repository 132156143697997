import { createStyles, makeStyles } from "@material-ui/core/styles";
import styled, { css } from "styled-components";

export default makeStyles(() =>
  createStyles({
    wrapper: {
      width: "80vw",
      backgroundColor: "#FFF",
      padding: "30px 30px",
      "@media (max-width: 960px)": {
        padding: "30px 10px",
      },
    },
    title: {
      fontSize: "2rem",
      margin: "1.2rem 0",
      fontWeight: 700,
      opacity: ".8",
    },
    divider: {
      height: "2px",
      margin: "1.6rem auto",
      backgroundColor: "#C0B021",
    },

    dividerHeader: {
      height: "2px",
      marginBottom: "1rem",
      backgroundColor: "#C0B021",
    },
    imgExit: {
      height: "35px",
      cursor: "pointer",
      position: "absolute",
      top: "0",
      right: "0",
    },
    gridContainer: {
      width: "100%",
    },
  })
);

export const ButtonExit = styled.div`
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  right: 40px;
  color: #fff;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background-color: #c0b021;
`;
