import { createStyles, fade, makeStyles, Theme } from "@material-ui/core/styles";


export default makeStyles((theme: Theme) => createStyles({
  contCardCollection: {
    cursor: "pointer",
    height: "100vh",
    "&:first-child": {
      borderBottom: ".7rem solid #c0b021",
    },
    "&:nth-child(2)": {
      borderBottom: ".7rem solid #aa991d",
    },
    "&:nth-child(3)": {
      borderBottom: ".7rem solid #7c6d18",
    },
  },
  btnMore: {
    marginTop: "1rem",
    display: "block",
    color: "#ffffff",
    textTransform: "uppercase",
    fontSize: "18px",
    border: ".1rem solid #ffffff",
    padding: ".5rem 1rem",
    width: "fit-content",
    margin: "0 auto",
    position: "relative",
    overflow: "hidden",
    letterSpacing: ".1rem",
    zIndex: 1,
    textDecoration: "none",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
    "&:before": {
      content: "''",
      position: "absolute",
      width: 0,
      height: "100%",
      left: "-40%",
      top: 0,
      zIndex: "-1",
      transition: "0.4s all ease-in",
      transform: "skew(50deg)",
      transformOrigin: "top left",
      background: fade(theme.palette.primary.main, 0.8),
    },
  },
  cardCollection: {
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    "&:hover": {
      "& $btnMore": {
        "&:before": {
          width: "140%",
        },
      },
    },
    "& img": {
      position: "absolute",
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },

  },
  bgCard: {
    width: "100%",
    height: "100%",
    position: "relative",
    background: `linear-gradient(to bottom, transparent, transparent, transparent, ${fade("#000000", 0.35)}, ${fade("#000000", 0.78)}), linear-gradient(to top, transparent, transparent, transparent, ${fade("#000000", 0.35)}, ${fade("#000000", 0.78)})`,
  },
  cardText: {
    width: "100%",
    position: "absolute",
    textAlign: "center",
    paddingTop: "35%"
  },
  titleCard: {
    color: "#ffffff",
    textTransform: "uppercase",
    letterSpacing: ".3rem",
    fontSize: "29px",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      letterSpacing: ".2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      letterSpacing: ".15rem",
    },
  },
}));