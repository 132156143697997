import {
  makeStyles,
  Theme,
} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => ({
  tableHead: {
    "& th": {
      fontSize: "1.2rem",
      textAlign: "center",
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      paddingBottom: "30px",
      paddingTop: "30px",
      color: theme.palette.primary.main,
    },
  },
  tableBody: {
    "& td": {
      fontSize: "1.2rem",
      textAlign: "center",
    },
  },
  cardContainer: {
    display: 'flex',
    marginBottom: "20px",
    boxShadow:"none",
    borderRadius: "0px",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    paddingBottom: "20px",
    paddingTop: "20px",
    justifyContent: "space-evenly",
    textAlign: "left",
  },
  detailsCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: "center",
  },
  contentCard: {
    flex: '1 0 auto',
    padding: "0",
  },
  titleCard: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    marginTop: "5px",
    marginBottom: "10px",
  },
}));

 