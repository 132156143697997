// Import core modules
// Import Material Ui / Formik Related
import { Container, Hidden, IconButton, Input, Paper } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { SearchIcon } from "Components/CustomIcons";
import SubMenu from "Components/SubMenu";
// Import Locals
import { DefaultValues, SearchContext } from "Context/Search/SearchContext";
import i18n from "i18next";
import React, { Children, Dispatch, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import api from "Utils/AxiosConfig";
import useStyles from "./styles";



interface ISubCategory {
  id: number;
  nameEs: string;
  nameEn: string;
  attribute: {
    id: number;
    name: string;
    name_es: string;
    name_en: string;
  };
  attribute2: {
    id: number;
    name: string;
    name_es: string;
    name_en: string;
  } | null;
  attribute3: {
    id: number;
    name: string;
    name_es: string;
    name_en: string;
  } | null;
}

interface ICategory {
  id: number;
  nameEs: string;
  nameEn: string;
  attribute: {
    id: number;
    name: string;
    nameEs: string;
    nameEn: string;
  };
  attribute2: {
    id: number;
    name: string;
    nameEs: string;
    nameEn: string;
  } | null;
  attribute3: {
    id: number;
    name: string;
    nameEs: string;
    nameEn: string;
  } | null;
  menuSet: Array<ISubCategory>;
  show: boolean;
}
interface IMenu {
  closeMenu: Dispatch<React.SetStateAction<boolean>>;
}

export default function MenuSideBar({ closeMenu }: IMenu) {
  const classes = useStyles();
  const [menuBack, setMenuBack] = useState<ICategory[]>([]);
  const stateDefault = DefaultValues;
  const { setSearchFilter } = useContext(SearchContext);
  const { t } = useTranslation();
  const [productName, setProductName] = useState("");
  const history = useHistory();
  const { setSearchFilter: setSearchBarFilter } = useContext(SearchContext);

  const { data } = useQuery(
    "menu_list",
    async () => api.get("api/products/menus/"),
    {
      onSuccess: (data) => {
        setMenuBack(
          data.data.map(
            (item: {
              id: number;
              name_es: string;
              name_en: string;
              attribute: {
                id: number;
                name: string;
                name_es: string;
                name_en: string;
              };
              attribute_2: {
                id: number;
                name: string;
                name_es: string;
                name_en: string;
              } | null;
              attribute_3: {
                id: number;
                name: string;
                name_es: string;
                name_en: string;
              } | null;
              menu_set: [];
            }) => {
              const isShow = {
                id: item.id,
                nameEs: item.name_es,
                nameEn: item.name_en,
                attribute: {
                  id: item.attribute.id,
                  name: item.attribute.name,
                  nameEs: item.attribute.name_es,
                  nameEn: item.attribute.name_en,
                },
                attribute2: item.attribute_2 ? {
                  id: item.attribute_2.id,
                  name: item.attribute_2.name,
                  nameEs: item.attribute_2.name_es,
                  nameEn: item.attribute_2.name_en,
                } : null,
                
                attribute3: item.attribute_3 ? {
                  id: item.attribute_3.id,
                  name: item.attribute_3.name,
                  nameEs: item.attribute_3.name_es,
                  nameEn: item.attribute_3.name_en,
                } : null,
                menuSet: item.menu_set,
                show: false,
              };
              return isShow;
            }
          )
        );
      },
    }
  );

  const handleItemMenu = (idAttributes: number, idAttributes2: number | null, idAttributes3: number | null) => {
    closeMenu(false);
    setSearchFilter({ ...stateDefault, idAttributes: idAttributes, idAttributes2: idAttributes2, idAttributes3: idAttributes3 });
  };

  const handleSubmenu = (
    id: number,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    const element = e.target as Element;
    const parent = element.parentElement;
    parent?.classList.toggle(classes.rotate);

    const newSubMenuArr = menuBack.map((item: ICategory) => {
      if (item.id === id) {
        item.show = !item.show;
      }
      return item;
    });
    setMenuBack(newSubMenuArr);
  };

  const handlePushShearch = () => {
    setSearchBarFilter({ ...stateDefault, productName: productName });
    closeMenu(false);
    history.push("/search");
  };

  const handleChangeInput = (e: { target: { value: string } }) => {
    setProductName(e.target.value);
  };

  return (
    <div className={classes.menuSidebar}>
      <Container>
        <Hidden only={["md", "lg", "xl"]}>
          <Paper className={classes.searchResponsive}>
            <IconButton>
              <SearchIcon fontSize={"small"} />
            </IconButton>
            <Input
              placeholder={t("Search")}
              disableUnderline={true}
              value={productName}
              onChange={(e) => handleChangeInput(e)}
              onKeyPress={(e) => {
                if (e.key === "Enter") handlePushShearch();
              }}
            />
          </Paper>
        </Hidden>
        {menuBack && (
          <ul className={classes.navColumn}>
            {Children.toArray(
              menuBack.map((category: ICategory) => 
                  <li>
                  <Link
                    to="/search"
                    onClick={() => handleItemMenu(category.attribute.id, category.attribute2 ? category.attribute2.id : null, category.attribute3 ? category.attribute3.id : null )}
                  >
                    {i18n.language === "ES"
                      ? category.nameEs
                      : category.nameEn}
                  </Link>
                  {category.menuSet.length !== 0 && (
                    <>
                      <IconButton
                      className={classes.toggleMenu}
                      onClick={(e) => handleSubmenu(category.id, e)}
                      >
                        <ArrowDropDownIcon />
                      </IconButton>
                      <div
                        id={`subMenu${category.attribute.id}`}
                        className={
                          category.show ? classes.active : classes.collapse
                        }
                      >
                      <SubMenu
                        subCat={category.menuSet}
                        closeSubMenu={closeMenu}
                      />
                      </div>
                    </>
                  )}
                </li>
              )
            )}
          </ul>
        )}
      </Container>
    </div>
  );
}
