import React from "react";

import { Box, Typography } from "@material-ui/core";

import useStyles from "./styles";
import { useTranslation } from "react-i18next";

export default function Pagination({count}:any) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box textAlign="right">
      <Typography className={classes.textPagination}>
        {t("Showing results")}
        <span> {count} </span>
      </Typography>
    </Box>
  );
}
