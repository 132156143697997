import React from "react";

import { Breadcrumbs, Hidden, Link, Typography } from "@material-ui/core";
import { FaHome } from "react-icons/fa";

import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { BreadCrumbsModels } from "Common/Types/Categories";

export default function BreadCrumbs({title}:BreadCrumbsModels) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Hidden only={["xs"]}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
        <Link color="inherit" href="/" className={classes.link}>
          <FaHome className={classes.icon} />
        </Link>
        <Link
          color="inherit"
          href="/search"
          className={classes.link}
        >
          {t("Products")}
        </Link>
        <Typography className={classes.link}>
          {title}
        </Typography>
      </Breadcrumbs>
    </Hidden>
  );
}
