import { BackendUser } from 'Common/Types/BackendTypes';
import { User } from 'Common/Types/UserType';
import i18n from "i18next";


export const serializeUserToBackend = ( user: User) : BackendUser => {
    const serializedUser : BackendUser = {
        user: {
            name: user.name,
            email: user.email,
            password: user.password,
        },
        representative : user.representative,
        website: user.webSite,
        country: user.country,
        interested_business: user.interestedBusiness,
        comentaries: user.comentaries,
        tax_id: user.taxId,
        address: user.address,
        city: user.city,
        phone_number: user.phoneNumber,
        preferred_language: i18n.language,
    }
    return serializedUser;
}