// Import core modules
import React, { useState, MouseEvent } from "react";
import Axios from "axios";
import { Field, Form, Formik } from "formik";
import {useHistory} from 'react-router-dom';
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

// Import Material Ui / Formik Related
import { Box, Container, Grid, Typography,
    Button,
    FormControl,
    FormGroup,
    FormLabel,
    useMediaQuery,
    InputAdornment, 
    IconButton
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import { useTheme } from "@material-ui/core/styles";

// Import Locals
import ConfirmModal from "Components/ConfirmModal";
import CountryType from "Common/Types/CountryType";
import { serializeUserToBackend } from "Utils/Serializers/serializeUserToBackend";
import { BackendUser } from 'Common/Types/BackendTypes';
import { CountriesAutocomplete } from "Components/CountriesAutocomplete";
import useStyles from "./styles";
import {countryList} from "../../Utils/countries";

const INITIAL_VALUES = {
    name: "",
    password: "",
    representative: "",
    email: "",
    webSite: "",
    country: "",
    comentaries: "",
    taxId:"",
    address:"",
    city:"",
    phoneNumber:"",
    interestedBusiness: [""]
};


export default function RegisterPage() {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    const validationSchema = () =>
      Yup.object({
        name: Yup.string().required(),
        representative: Yup.string().required(),
        email: Yup.string().required().email(),
        password: Yup.string()
          .required()
          .min(8, t("The password must have a minimum of 8 characters.")),
        country: Yup.string().required(),
        taxId: Yup.string().required(),
        address: Yup.string().required(),
        phoneNumber: Yup.string().required(),
      });

    const [showPassword, setShowPassword] = useState(false)
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const history = useHistory();
    const apiBase: string = process.env.REACT_APP_API_LINK as string;
    const [repeated, setRepeated] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false)

    const [businessType, setBusinessType] = useState({
      franchises: true,
      wholesaler: false,
      b2b: false,
    });

    const { franchises, wholesaler, b2b } = businessType;
    const error = [franchises, wholesaler, b2b].filter((v) => v).length === 0;

    const countries: CountryType[] = countryList;
    
    const business: string[] = [];

    const mutation = useMutation(
        (data: BackendUser) => Axios.post(apiBase + "api/users/users/", data),
        {
            onError(error) {
                setRepeated(true);
                console.error(error);
            },
            onSuccess(res) {
                setShowConfirm(true)
            },
        }
    );

    const addBusiness = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBusinessType({
        ...businessType,
        [event.target.name]: event.target.checked,
        });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: MouseEvent) => {
        event.preventDefault();
    };

    const Submit = (values: any) => {

        if (franchises === true) {
        business.push("F");
        }
        if (wholesaler === true) {
        business.push("W");
        }
        if (b2b === true) {
        business.push("B");
        }
        if (business.length > 0) {
        values.interestedBusiness = business
        mutation.mutate(serializeUserToBackend(values));
        }
    };

    const successRegister = ()=>{
        setShowConfirm(false)
        history.push('/login');
    }

    return (
        <>
        <Grid container className={classes.contBanner}>
            <Container maxWidth="md">
                <Typography
                    variant="h1"
                    component="h2"
                    className={classes.titlePage}
                >
                    {t('Register')}
                </Typography>
                <div className={classes.contFormRegister}>
                    <Formik
                        initialValues={INITIAL_VALUES}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        Submit(values);
                        }}
                    >
                        {({ submitForm, isSubmitting, touched, values }) => (
                        <Form>
                            <div>
                            <Grid container spacing={matches ? 7 : 2}>
                                <Grid item sm={6} xs={12}>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <Field
                                        component={TextField}
                                        name="name"
                                        type="text" 
                                        label={t("Enterprise name (*)")}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <Field
                                        component={TextField}
                                        name="representative"
                                        type="text"
                                        label={t("Name of the representative (*)")}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <Field
                                        component={TextField}
                                        name="phoneNumber"
                                        type="text"
                                        label={t("Phone Number (*)")}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <Field
                                        component={TextField}
                                        name="webSite"
                                        type="url"
                                        label={t("Web page")}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <Field
                                        component={TextField}
                                        name="email"
                                        type="email"
                                        label={t("Email (*)")}
                                        error={(touched.email && values.email === "") || repeated}
                                        helperText={
                                            repeated ? t("Email already exists") : ""
                                        }
                                        />
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <Field
                                        component={TextField}
                                        name="password"
                                        type={showPassword ? 'text' : 'password'}
                                        label={t("Password (*)")}
                                        />
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                            <Field
                                            component={TextField}
                                            name="address"
                                            type="text"
                                            label={t("Address (*)")}
                                            />
                                    </FormControl>                                    
                                    <FormControl fullWidth className={classes.formControlCountries}>
                                        <CountriesAutocomplete
                                        countries={countries}
                                        touched={touched}
                                        values={values.country}
                                        />
                                        <FormControl fullWidth className={classes.formControl}>
                                            <Field
                                            component={TextField}
                                            name="city"
                                            type="text"
                                            label={t("City")}
                                            />
                                        </FormControl>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={6} xs={12}>

                                    <FormControl fullWidth className={classes.formControl}>
                                            <Field
                                            component={TextField}
                                            name="taxId"
                                            type="text"
                                            label={t("Tax ID (*)")}
                                            />
                                    </FormControl>
                                    <FormControl
                                        required
                                        error={error}
                                        fullWidth
                                        className={classes.checkGroup}
                                    >
                                        <FormLabel
                                        component="legend"
                                        className={classes.labelCheckGroup}
                                        >
                                        {t("Interested businesses")}
                                        </FormLabel>
                                        <FormGroup>
                                        <Field
                                            component={CheckboxWithLabel}
                                            type="checkbox"
                                            name="franchises"
                                            color="primary"
                                            onChange={addBusiness}
                                            indeterminate={false}
                                            checked={franchises}
                                            Label={{ label: t("Franchises") }}
                                        />
                                        <Field
                                            component={CheckboxWithLabel}
                                            type="checkbox"
                                            name="wholesaler"
                                            color="primary"
                                            onChange={addBusiness}
                                            checked={wholesaler}
                                            indeterminate={false}
                                            Label={{ label: t("Wholesalers") }}
                                        />
                                        <Field
                                            component={CheckboxWithLabel}
                                            type="checkbox"
                                            name="b2b"
                                            color="primary"
                                            onChange={addBusiness}
                                            checked={b2b}
                                            indeterminate={false}
                                            Label={{ label: t("B2B") }}
                                        />
                                        </FormGroup>
                                    </FormControl>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <Field
                                        component={TextField}
                                        name="comentaries"
                                        label={t("Additional comments")}
                                        variant="outlined"
                                        multiline
                                        rows={6}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            </div>
                            <Box display="flex" justifyContent="center">
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}
                                type="submit"
                                className={classes.btnSend}
                            >
                                {t("Register at")}
                            </Button>
                            </Box>
                        </Form>
                        )}
                    </Formik>
                    </div>
            </Container>
        </Grid>
        
            <ConfirmModal  
                openModal={showConfirm} 
                closeModal={() => successRegister()} 
                message={t("You have successfully registered, you will receive an email once your account has been accepted.")}
                title={t("Success")}
            />        
        </>
    );
}
