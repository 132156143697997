// Import core modules
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// Import Material Ui / Formik Related
import {
  Container,
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";

// Import Locals
import useStyles from "./styles";
import { ExpandMoreIcon } from "Components/CustomIcons";

export default function TermsConditionsPage() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Container maxWidth="md" className={classes.contBanner}>
      <Box>
        <Typography variant="h1" className={classes.title}>
          {t("Terms & Conditions")}
        </Typography>
      </Box>
      <Box className={classes.boxAccordion}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.titleAccordion}>
              Métodos de Pago
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.subtitle}>
              Métodos de pago disponibles en la tienda KUNA online Perú
              kunastores.com/peru
            </Typography>
            <Typography className={classes.text}>
              Una vez añadidos los artículos de su preferencia a la Bolsa de
              Compras, el cliente deberá registrar sus datos para que pueda
              efectuar el pago, mismo que podrá hacerse en cualquiera de estas
              modalidades:
            </Typography>
            <Typography className={classes.text}>
              <ul>
                <li>Pago con Tarjeta de Crédito/Débito.</li>
              </ul>
            </Typography>
            <Typography className={classes.text}>
              <b>Pago con Tarjeta de Crédito/Débito</b>
            </Typography>
            <Typography className={classes.text}>
              Los productos ofertados en esta plataforma se comercializan de
              forma segura. Para brindarle esta garantía al cliente, la Empresa
              posee un acuerdo con un proveedor externo de servicios de pago en
              línea, de amplia experiencia y con presencia en mercados de alto
              crecimiento.
            </Typography>
            <Typography className={classes.text}>
              Los datos suministrados por el cliente serán compartidos con el
              proveedor para fines exclusivos de prestación del servicio, los
              cuales incluyen el procesamiento de pagos y la validación de
              transacciones a través de un sistema anti fraude, a fin de
              minimizar el riesgo de suplantación de identidad de los tarjeta
              habientes.
            </Typography>
            <Typography className={classes.text}>
              Al efectuar el pago mediante esta modalidad, el cliente autoriza
              el tratamiento confidencial de la información por el proveedor.
              Los datos personales, de la tarjeta de crédito y de la compra
              viajan encriptados en la red, quedando así protegidos.
            </Typography>
            <Typography className={classes.text}>
              La Empresa, por otro lado, no se responsabiliza por las posibles
              comisiones que los operadores de las tarjetas de crédito pudiesen
              aplicar.
            </Typography>
            <Typography className={classes.text}>
              Las tarjetas de crédito/débito mediante las cuales se puede
              efectuar el pago son:
            </Typography>
            <Typography className={classes.text}>
              <ul>
                <li>VISA</li>
                <li>Amex (American Express).</li>
                <li>MasterCard.</li>
                <li>Dinners</li>
              </ul>
            </Typography>
            <Typography className={classes.text}>
              Antes de cerrar su compra y habiendo consignado sus datos
              personales y de envío, el cliente ingresará los datos de su
              tarjeta de crédito, en la cual se cargará el importe total
              suministrado por la Plataforma.
            </Typography>
            <Typography className={classes.text}>
              La confirmación de la Orden de Compra se llevará a cabo una vez
              que la instancia correspondiente verifique que el pago
              efectivamente se realizó. Para ello, la empresa posee una cuenta
              virtual, proporcionada por el proveedor del sistema de pago, a
              través de la cual puede consultar en tiempo real, el estado de las
              transacciones hechas por el cliente.
            </Typography>
            <Typography className={classes.text}>
              Con la confirmación lista, se procede a iniciar el proceso de
              despacho, a partir del cual empieza a correr el plazo asociado a
              la modalidad de entrega (Despacho vía courrier)
            </Typography>
            <Typography className={classes.text}>
              En caso se presentase algún problema con el pago efectuado, el
              cliente podrá ponerse en contacto con la empresa.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography className={classes.titleAccordion}>
              Política de envío
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.subtitle}>
              Envío de pedidos realizados en kunastores.com/peru
            </Typography>
            <Typography className={classes.text}>
              Para que el envío del pedido realizado en la tienda online de KUNA
              Perú (kunastores.com/peru) se lleve a cabo exitosamente, es decir,
              de manera adecuada y oportuna, el cliente deberá consignar la
              información correcta al momento de ejecutar la Orden de Compra.
            </Typography>
            <Typography className={classes.text}>
              El registro de datos erróneos o incompletos que ocasione un
              procesamiento indebido de la misma, será de exclusiva
              responsabilidad del cliente. Ello podría devenir incluso en una
              cancelación de la orden si así lo considera pertinente la Empresa.
            </Typography>
            <Typography className={classes.text}>
              El plazo de entrega del pedido se contabiliza desde que se valida
              la confirmación el pago de la Orden de Compra. La extensión de
              dicho plazo dependerá principalmente de la dirección de entrega.
            </Typography>
            <Typography className={classes.text}>
              La tienda online KUNA contempla una forma de entrega:
              <ul>
                <li>Despacho vía Courier</li>
              </ul>
            </Typography>
            <Typography className={classes.text}>
              Tiempo promedio de entrega:
              <ul>
                <li>Lima: 5 - 7 días hábiles</li>
                <li>Provincias: 7 - 10 días hábiles</li>
              </ul>
            </Typography>
            <Typography className={classes.text}>
              Envíos a las capitales de las principales provincias de los
              siguientes destinos nacionales:
              <ul>
                <li>
                  Chimbote - Áncash, Huaraz - Áncash, Ica, Lima, Cajamarca,
                  Trujillo - La Libertad, Chiclayo -Lambayeque, Piura, Tumbes,
                  Abancay - Apurímac, Ayacucho, Cusco, Moquegua, Ilo, Puno,
                  Tacna.
                </li>
              </ul>
            </Typography>
            <Typography className={classes.text}>
              Pueden haber costos y tiempos de entrega adicionales para envíos a
              ciudades y distritos alejados. Consultar previamente a la línea de
              atención 994 088 248 o al correo e-kuna@incalpaca.com
            </Typography>
            <Typography className={classes.text}>
              Agradeceríamos considerar que todos los despachos salen de la
              Ciudad de Arequipa- Perú, y en temporada de Fiestas (Fiestas
              Patrias, Navidad y Año Nuevo) o campañas (Black Friday, Cyber
              Days, Cyber Monday, Online Sale, Summer Sale), existe la
              probabilidad de retraso de envío en 3 o 4 días hábiles más de lo
              normal debido a la saturación de pedidos.
            </Typography>
            <Typography className={classes.text}>
              El pedido deberá ser recibido por una mayor de edad, el cual se
              identificará con su Nombre Completo y Documento Nacional de
              Identidad (DNI) en físico y firmará el cargo de recepción para
              acreditar la conformidad de entrega.
            </Typography>
            <Typography className={classes.text}>
              La Empresa realizará un seguimiento continuo a las órdenes
              despachadas, con el fin de asegurarse que estas lleguen a su
              destino en el tiempo acordado. Sin embargo, la entrega podría no
              concretarse por cualquiera de las siguientes situaciones:
              <ul>
                <li>
                  La dirección de entrega no fue ubicada porque el registro de
                  la misma en la Plataforma se llevó a cabo de forma equívoca o
                  incompleta.
                </li>
                <li>
                  No se encontraron moradores en el domicilio de envío al
                  momento de realizar la entrega.
                </li>
              </ul>
            </Typography>
            <Typography className={classes.text}>
              Una vez informada del inconveniente, la Empresa se pondrá en
              contacto con el Cliente vía correo electrónico y/o teléfono, para
              hacer la reprogramación de la fecha de entrega. Los costos
              adicionales que suponga esta reprogramación serán asumidos por el
              cliente.
            </Typography>
            <Typography className={classes.text}>
              En caso de que el cliente requiera información adicional sobre el
              estado de envío de su pedido, podrá comunicarse con la Empresa a
              través de la línea de Atención al Cliente (+51 54 60 3000 anexo
              504), la cual está disponible en el horario de Lunes a Viernes,
              desde las 9:00 hasta las 12:00 hrs. y de las 14:00 a las 17:00 hrs
              en Perú; o enviar un correo electrónico a e-kuna@incalpaca.com
            </Typography>
            <Typography className={classes.text}>
              <b>PROTOCOLO DE BIOSEGURIDAD (COVID-19):</b>
            </Typography>
            <Typography className={classes.text}>
              Nuestra prioridad es la salud de nuestros clientes y
              colaboradores, por ello le pedimos tomar nota del protocolo de
              bioseguridad que hemos implementado en nuestro centro de
              distribución donde se procesa su compra:
              <ul>
                <li>
                  Realizamos exhaustiva limpieza, desinfección de ambientes y
                  áreas de trabajo.
                </li>
                <li>
                  Nuestros colaboradores pasan un control diario de temperatura,
                  hacen uso obligatorio de un equipo de protección personal
                  (EPP) para la prevención del COVID-19, y deben lavarse y
                  desinfectarse las manos constantemente.
                </li>
              </ul>
            </Typography>
            <Typography className={classes.text}>
              <b>Consideraciones</b>
            </Typography>
            <Typography className={classes.text}>
              La Empresa se exime de responder, bajo toda circunstancia, por los
              posibles inconvenientes que pudiera generar la prestación de
              servicios de entrega de productos brindados por proveedores
              externos.
            </Typography>
            <Typography className={classes.text}>
              Asimismo, de presentarse el caso excepcional que los productos
              adquiridos por el Cliente no tuviesen stock disponible, se le hará
              llegar un correo electrónico informándole la situación, quedando
              este en la libertad de anular el pedido o hacer uno nuevo a través
              de la Plataforma.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography className={classes.titleAccordion}>
              Política de cambios y devoluciones
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.subtitle}>
              Cambios y devoluciones
            </Typography>
            <Typography className={classes.text}>
              Es prioridad de la Empresa ofrecer productos de excelente calidad
              y un alto nivel de servicio. Por ello, el Cliente está en la
              potestad de solicitar un cambio si no está satisfecho con su
              compra. Para lo cual el cliente, deberá realizar una solicitud
              formal vía correo electrónico e-kuna@incalpaca.com, luego de ser
              revisada y aprobada podrá apersonarse a la tienda KUNA indicada
              para realizar el cambio respectivo.
            </Typography>
            <Typography className={classes.text}>
              No obstante, la Empresa se reserva el derecho de aceptar o
              rechazar cualquiera de estas solicitudes dependiendo de las
              particularidades del caso materia de evaluación.
            </Typography>
            <Typography className={classes.text}>
              Siempre que lo necesite, podrá contactarse con la empresa mediante
              la línea de Atención al Cliente +51 54 603000 anexo 504 (Lunes a
              Viernes de 9 a 12h y de 14 a 17h) o al Whatsapp + 51 994088248.
            </Typography>
            <Typography className={classes.text}>
              Para fines de esta política, entiéndase cambio como una solicitud
              hecha por el cliente, que tiene como objetivo cambiar un producto
              por otro de la marca KUNA.
            </Typography>
            <Typography className={classes.text}>
              Los cambios de prendas podrán aceptarse si existen:
              <ul>
                <li>
                  Problemas de Calidad (responsabilidad de la empresa): Incluyen
                  pérdida de color, sangrados, ruptura de costuras, excesivo
                  pilling, etc.
                </li>
                <li>
                  Otros Problemas (ajenos a la empresa): Son errores
                  involuntarios del cliente al escoger el tipo de prendas, por
                  ejemplo en lo que respecta a talla, color o modelo.
                </li>
                <li>
                  El cliente tendrá un plazo de 30 días calendario como máximo,
                  contados a partir de la fecha de recepción del pedido, para
                  presentar su solicitud de cambio vía correo electrónico
                  e-kuna@incalpaca.com.
                </li>
              </ul>
            </Typography>
            <Typography className={classes.text}>
              Esta será viable siempre y cuando se asegure que el producto:
              <ul>
                <li>Presente boleta de venta en físico.</li>
                <li>
                  Ha sido adquirido a través de la plataforma de E-Commerce de
                  KUNA Perú (kunastores.com/peru).
                </li>
                <li>La prenda se encuentre en perfecto estado.</li>
                <li>Cuenta con los envases y etiquetas originales.</li>
                <li>No muestre señales de uso o daño.</li>
                <li>
                  El cliente, podrá apersonarse a cualquier tienda KUNAen Perú
                  con el (los) producto(s) en perfecto estado, y se ajustará a
                  las políticas de la tienda física.
                </li>
              </ul>
            </Typography>
            <Typography className={classes.text}>
              El cliente, que haya hecho su compra en una provincia de Perú en
              donde no hayan tiendas físicas KUNA, necesariamente deberá
              devolver el (los) producto(s) en perfecto estado vía Courier, a la
              dirección en Arequipa: Calle Cóndor Nro. 100 Urb. Tahuaycani,
              Distrito de Sachaca, Provincia y Departamento de Arequipa (Dicho
              envío será por cuenta del cliente)
            </Typography>
            <Typography className={classes.text}>
              La Empresa no hará ningún abono en efectivo ni por saldos a favor
              del cliente.
            </Typography>
            <Typography className={classes.text}>
              <b>Devoluciones</b>
            </Typography>
            <Typography className={classes.text}>
              Para fines de ésta política, entiéndase devolución como una
              solicitud hecha por el cliente que tiene como objetivo devolver el
              producto por el monto pagado.
            </Typography>
            <Typography className={classes.text}>
              <b>No se aceptan devoluciones de dinero.</b>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
