import { useState } from "react";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";

import { Grid, IconButton, Typography, Link } from "@material-ui/core";
import { AiFillYoutube } from "react-icons/ai";
import { FaPinterestP } from "react-icons/fa";
import { GrFacebookOption } from "react-icons/gr";
import { IoLogoInstagram } from "react-icons/io5";

import grupoInca from "Assets/images/GrupoInca.svg";
import whyAlpaca from "Assets/images/WhyAlpaca.svg";
import RegisterModal from "Components/RegisterModal";
import useStyles from "./styles";

export default function Footer() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openModalRegister, setOpenModalRegister] = useState(false);

  const handleClickOpenModalRegister = () => {
    setOpenModalRegister(true);
  };

  const handleCloseModalRegister = () => {
    setOpenModalRegister(false);
  };

  return (
    <>
      <footer className={classes.footer}>
        <Grid container className={classes.centerItems}>
          <Grid item lg={4} md={4} xs={12} sm={12}>
            <h4 className={classes.followUs}>{t("FOLLOW US")}</h4>
            <div className={classes.icons}>
              <IconButton
                href="https://www.facebook.com/KunaOfficial/"
                target="_blank"
                aria-label="facebook"
              >
                <GrFacebookOption className={classes.facebook} />
              </IconButton>
              <IconButton
                href="https://www.instagram.com/kunaofficial/?hl=es-la"
                target="_blank"
                aria-label="instagram"
              >
                <IoLogoInstagram className={classes.instagram} />
              </IconButton>
              <IconButton
                href="https://www.youtube.com/channel/UCphZSDxIe_qRn2iPfMTNY4A"
                target="_blank"
                aria-label="youtube"
              >
                <AiFillYoutube className={classes.youtube} />
              </IconButton>
              <IconButton
                href="https://www.pinterest.ca/kunaofficial/_created/"
                target="_blank"
                aria-label="pinterest"
              >
                <FaPinterestP className={classes.pinterest} />
              </IconButton>
            </div>
          </Grid>
          <Grid item lg={4} md={4} xs={12} sm={12} className={classes.links}>
            <h4 className={classes.aboutUs}>{t("ABOUT US")}</h4>
            <Link href="/" underline="none">
              {t("Inca Group")}
            </Link>
            <Link href="/" underline="none">
              Incalpaca
            </Link>
            <Link
              href="https://www.whyalpaca.com/"
              target="_blank"
              underline="none"
            >
              {t("Why Alpaca")}
            </Link>
            <Link href="/" underline="none">
              {t("Our Essence")}
            </Link>
            <Link href="/" underline="none">
              {t("Our Fiber")}
            </Link>
            <Link href="/termsConditions" target="_blank" underline="none">
              {t("Terms & Conditions")}
            </Link>
            <Link href="/privacyPolicy" target="_blank" underline="none">
              {t("Privacy Policy")}
            </Link>
            <br />
          </Grid>
          <Grid item lg={4} md={4} xs={12} sm={12}>
            <Grid container className={classes.containerItems}>
              <Grid item lg={3} md={5} xs={12} sm={12}>
                <IconButton
                  disableRipple
                  href="http://alpacaisalifestyle.com/"
                  target="_blank"
                  className={classes.btnIncaGroup}
                >
                  <ReactSVG src={grupoInca} className={classes.incaGroup} />
                </IconButton>
              </Grid>
              <Grid item lg={3} md={5} xs={12} sm={12}>
                <IconButton
                  disableRipple
                  href="https://www.whyalpaca.com/"
                  target="_blank"
                  className={classes.btnWhyAlpaca}
                >
                  <ReactSVG src={whyAlpaca} className={classes.whyAlpaca} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </footer>
      <RegisterModal
        openModal={openModalRegister}
        closeModal={handleCloseModalRegister}
      />
    </>
  );
}
