import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";


export default function ConfirmModal({
  openModal,
  closeModal,
  message,
  title,
}: {
  openModal: boolean;
  closeModal: () => void;
  message: string;
  title:string;
}) {
  const { t }= useTranslation();
  return (
    <div>
      <Dialog
        open={openModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            {t('Accept')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
