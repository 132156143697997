import { createStyles, fade, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => createStyles({
  contProducts: {
    marginBottom: "2rem",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "2.5rem",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: fade(theme.palette.info.main, 0.05),
    },
    "&::-webkit-scrollbar": {
      width: " 3px",
      backgroundColor: fade(theme.palette.info.main, 0.05),
    }, "&::-webkit-scrollbar-thumb":
    {
      backgroundColor: fade(theme.palette.info.main, 0.6),
    },
  },
  itemProduct: {
    paddingRight: "1rem",
    paddingBottom: "1rem",
    [theme.breakpoints.down("md")]: {
      paddingRight: "1rem",
      paddingBottom: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: "0",
      paddingBottom: "1rem",
    },
  },
  textNotFoundResults: {
    fontSize: "1.3rem",
    lineHeight: "normal",
    color: fade(theme.palette.info.main, 0.4),
  },
}));
