import { Box, Button, Grid, Typography } from "@material-ui/core";
import {
  GeneralsContext,
  GeneralsContextInterface
} from "Context/Generals/GeneralsContext";
import { DefaultValues, SearchContext } from "Context/Search/SearchContext";
import i18n from "i18next";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Img } from "react-image";
import { useHistory } from "react-router-dom";
import useStyles from "./styles";

export default function GridCollections() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setSearchFilter } = useContext(SearchContext);
  const { homepageImages } = useContext<GeneralsContextInterface>(GeneralsContext);
  const stateDefault = DefaultValues;
  const history = useHistory();
  
  console.log("re", homepageImages);
  const onClick = (item: any) => {
    console.log("click");
    if (item.url){
      console.log("url");
      window.location.href = item.url;
    }
    else{
      console.log("put filters");
      setSearchFilter({ ...stateDefault, idAttributes: item.attribute, idAttributes2: item.attribute2, idAttributes3: item.attribute3 });
      history.push("/search");
    }
  }
  return (
    <Grid container>
      <>
        {React.Children.toArray(
          homepageImages?.slice(0, 3).map((item) => (
            <>
              <Grid
                item
                md={4}
                sm={12}
                xs={12}
                className={classes.contCardCollection}
              >
                <Button
                  onClick={() => {onClick(item);}}
                  className={classes.cardCollection}
                >
                  <Img src={item.photoThumbnail} />
                  <Box className={classes.bgCard}>
                    <Box className={classes.cardText}>
                      <Typography variant="h4" className={classes.titleCard}>
                        {i18n.language === "ES" ? item.nameEs : item.nameEn}
                      </Typography>
                      <div className={classes.btnMore}>{t("See here")}</div>
                    </Box>
                  </Box>
                </Button>
              </Grid>
            </>
          ))
        )}
      </>
    </Grid>
  );
}
