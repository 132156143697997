import { useContext } from "react";

import {
  Box,
  Container,
  Hidden,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import BreadCrumbs from "Components/BreadCrumbs";
import ProductShopping from "Components/ProductShopping";
import { shoppingContext } from "Context/Shopping/ShoppingContext";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";


export default function ShoppingPage() {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { shopping } = useContext(shoppingContext);

  return (
    <Box className={classes.internalPage}>
      <Container maxWidth="xl" disableGutters={matches ? true : false}>
        <Box display="flex" flexWrap="wrap">
          <Box className={classes.right}>
            <Container disableGutters={matches ? false : true}>
              <BreadCrumbs title={t("SHOPPING CART")} />
                <Typography
                  variant="h3"
                  align="center"
                  color="primary"
                  className={classes.titlePage}
                >
                  {t("QUOTATION SUMMARY")}
                </Typography>
              <ProductShopping products={shopping.products} />
            </Container>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
