import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function SliderIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props} viewBox="0 0 69.7 66.7">
			<path d="M0.2,32.6c0.6-1.6,1.9-2.2,3.5-2.2c2.1,0,4.2,0,6.2,0c0.6,0,0.9-0.2,1.1-0.7c1.6-4,5.3-6.5,9.6-6.5
		c3.9,0,7.7,2.6,9.1,6.3c0.3,0.7,0.6,0.9,1.4,0.9c11.6,0,23.2,0,34.8,0c0.5,0,0.9,0,1.3,0.1c1.4,0.3,2.4,1.5,2.4,2.9s-1,2.6-2.4,2.9
		c-0.4,0.1-0.9,0.1-1.3,0.1c-11.6,0-23.2,0-34.9,0c-0.7,0-1,0.2-1.3,0.9c-1.5,3.8-5.3,6.4-9.3,6.4c-4.1,0-7.9-2.6-9.3-6.4
		c-0.3-0.7-0.6-0.9-1.3-0.9c-2,0-4.1,0-6.1,0c-1.7,0-2.9-0.6-3.5-2.2C0.2,33.6,0.2,33.1,0.2,32.6z M24.7,33.4c0-2.3-1.9-4.3-4.3-4.3
		c-2.3,0-4.2,1.9-4.2,4.2c0,2.4,1.8,4.2,4.2,4.3C22.7,37.7,24.7,35.7,24.7,33.4z"/>
			<path d="M0.2,55.7c0.6-1.6,1.7-2.2,3.4-2.2c11.7,0,23.4,0,35.1,0c0.7,0,1-0.2,1.3-0.9c1.5-3.7,4.4-5.9,8.3-6.3
		c4.2-0.4,7.4,1.3,9.7,4.8c0.3,0.5,0.6,0.9,0.7,1.5c0.2,0.7,0.6,0.9,1.3,0.9c2.2,0,4.4,0,6.6,0c2.1,0,3.5,2,2.8,3.9
		c-0.4,1.2-1.5,2-2.7,2c-2.3,0-4.6,0-6.9,0c-0.6,0-0.8,0.2-1.1,0.7c-1.7,4.1-4.8,6.4-9.3,6.6c-4.1,0.2-7.9-2.4-9.5-6.2
		c-0.5-1.2-0.3-1-1.5-1c-11.7,0-23.4,0-35.1,0c-1.8,0-2.6-0.5-3.3-2.2C0.2,56.7,0.2,56.2,0.2,55.7z M49.4,60.7
		c2.4,0,4.2-1.9,4.2-4.3c0-2.3-1.9-4.2-4.2-4.2c-2.3,0-4.2,1.9-4.2,4.2C45.1,58.9,47,60.7,49.4,60.7z"/>
			<path d="M0.2,9.5c0.7-1.7,1.5-2.2,3.3-2.2c11.8,0,23.5,0,35.3,0c0.7,0,1-0.2,1.2-0.8c1.5-3.8,5.2-6.4,9.3-6.5
		c4-0.1,8,2.6,9.4,6.3c0.3,0.7,0.6,1,1.5,1c2.1-0.1,4.3,0,6.4,0c1.8,0,3.1,1.2,3.1,2.9s-1.3,3-3.1,3c-2.2,0-4.4,0-6.6,0
		c-0.6,0-1,0.2-1.2,0.8c-2.4,6.2-10,8.4-15.4,4.4c-1.5-1.1-2.7-2.5-3.3-4.3c-0.3-0.7-0.6-0.9-1.4-0.9c-11.7,0-23.4,0-35.1,0
		c-1.7,0-2.8-0.6-3.4-2.2C0.2,10.5,0.2,10,0.2,9.5z M49.4,6c-2.4,0-4.3,1.9-4.3,4.2s1.9,4.2,4.2,4.2c2.4,0,4.2-1.9,4.2-4.3
		C53.6,7.8,51.8,6,49.4,6z"/>
		</SvgIcon>
	)
}
