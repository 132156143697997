import React, { useEffect, useState } from "react";

import { Box, Grid, Typography } from "@material-ui/core";

import { ProductType } from "Common/Types/Products";
import CardProduct from "../CardProduct";
import Pagination from "../Pagination";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";

export default function GridProducts(props: { data: ProductType[] }) {
  const { data } = props;
  const classes = useStyles();
  const [product, setProduct] = useState<ProductType[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setProduct(data);
  }, [data]);

  return (
    <Box>
      <Grid container spacing={0} className={classes.contProducts}>
        {product.length > 0 ? (
          <>
            {React.Children.toArray(
              product.map((item: ProductType) => (
                <Grid
                  item
                  lg={3}
                  md={4}
                  sm={6}
                  xs={12}
                  className={classes.itemProduct}
                >
                  <CardProduct
                    id={item.id}
                    colourSet={item.colourSet}
                    description={item.description || ""}
                    name={item.name}
                    nameEs={item.nameEs}
                    nameEn={item.nameEn}
                    price={item.price || 0}
                    productphotoSet={item.productphotoSet}
                  />
                </Grid>
              ))
            )}
          </>
        ) : (
          <Grid item lg={12}>
            <Box
              textAlign="center"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h4" className={classes.textNotFoundResults}>
                {t("We couldn't find any product matching these conditions.")}
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      <Pagination 
      count={product.length}
      />
    </Box>
  );
}
