import { ProductType, ColourSet, PhoModels, ShoppingCartModel, ShoppingCartType } from "Common/Types/Products";
import {
  BackendProductType,
  BackendColourSet,
  BackendPhoModels,
  BackendShoppingCart,
  BackendProductTypeUnique,
  BackendStockSetColour,
  BackendColourSetStock,
} from "Common/Types/BackendTypes";
import { parseJsonConfigFileContent } from "typescript";

export const serializeProductToContext = (
  product: BackendProductType[]
): ProductType[] => {
  const seriaLizeProductMap = product.map((item) => {
    const serializeProduct: ProductType = {
      id: item.id,
      name: item.name,
      nameEs: item.name_es,
      nameEn: item.name_en,
      price: item.price,
      description: item.description,
      colourSet: serializrColourSet(item.colour_set),
      productphotoSet: item.productphoto_set,
    };
    return serializeProduct;
  });
  return seriaLizeProductMap;
};

const serializrColourSet = (colourSet: BackendColourSet[]): ColourSet[] => {
  const format = colourSet.map((item, index) => {
    return {
      active: index === 0 ? true : false,
      id: item.id,
      colourphotoSet: serializrColourphotoSet(item.colourphoto_set),
    };
  });
  return format;
};

const serializrColourphotoSet = (
  colourphotoSet: BackendPhoModels[]
): PhoModels[] => {
  const format = colourphotoSet.map((item, index) => {
    return {
      photoThumbnail: item.photo_thumbnail,
      active: index === 0 ? true : false,
    };
  });
  return format;
};

export const serializerColourCart = (
  productsArr: ShoppingCartModel[],
  productsBD: BackendProductTypeUnique[],
):BackendShoppingCart => {
  const itemsColourSizes:ShoppingCartType[] = [];
  const cartApprove = productsArr.map((item, i) => {
    const orderBySizes = item.toCard.split(',');
    const sizes = orderBySizes.map(elem => elem.replace(/[0-9]/g,''))
    const orderAmounts = orderBySizes.map(elem => elem.replace(/[A-Z]/g,''))

    const productBackSelected:BackendProductTypeUnique[] = productsBD.filter(product => product.id === item.id)
    const stocksIds:BackendColourSetStock[] = productBackSelected[0].colour_set.filter((colour:BackendColourSetStock) => colour.id === item.color);
    stocksIds[0].stock_set.forEach((stock:BackendStockSetColour) => {
      sizes.forEach((size,i) => {
        if(size === stock.size){
          if(parseInt(orderAmounts[i]) > 0){
            itemsColourSizes.push({
              amount:parseInt(orderAmounts[i]),
              colour:item.color,
              size:stock.size_id
            })
          }
        }
      })
    
    })
    return itemsColourSizes;
  })
  
  return {
    item_set:itemsColourSizes
  };

}