import { createStyles, fade, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => createStyles({
  modalRegister: {
    "& .MuiPaper-rounded": {
      borderRadius: 0,
      [theme.breakpoints.down("sm")]: {
        margin: 20,
      },
    },
  },
  contTitleModalRegister: {
    padding: "1.1rem 1rem",
    textAlign: "center",
    textTransform: "uppercase",
    borderBottom: `.12rem solid ${theme.palette.primary.main}`,
    "& h2": {
      fontFamily: "'Helvetica', sans-serif",
      color: theme.palette.info.main,
      fontSize: "1.24rem",
      lineHeight: 1.1,
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
  },
  contModalRegister: {
    padding: "2rem 3rem 2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "1.2rem 1.4rem 2rem",
    },
  },
}));
