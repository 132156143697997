import { createContext } from "react";
import { CategoryType } from "Common/Types/Categories";
import { GroupAttributeType } from "Common/Types/GroupAttributes";
import { HomepageImageType } from "Common/Types/HomepageImageType";

export interface GeneralsContextInterface {
  categories: CategoryType[];
  attributes: CategoryType[];
  groupAttributes: GroupAttributeType[];
  homepageImages: HomepageImageType[];
}

export const DefaultValues: GeneralsContextInterface = {
  categories: [],
  attributes: [],
  groupAttributes: [],
  homepageImages: [],
};

export const GeneralsContext =
  createContext<GeneralsContextInterface>(DefaultValues);

GeneralsContext.displayName = "GeneralsContext";
