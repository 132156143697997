import { createStyles, makeStyles } from "@material-ui/core/styles";
import styled, { css } from "styled-components";

export default makeStyles(() =>
  createStyles({
    wrapper: {
      display: "flex",
    },
    photo: {
      borderRadius: '18px',
      objectFit: "none",
      objectPosition: "center",
    },
  })
);

export const Circle = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
  overflow: hidden;
  cursor: pointer;
  background: ${(props) => props.theme.color};
  border-radius: 18px; 
  ${(props) =>
    props.theme.active &&
    css`
      box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.1);
    `}
`;
