import { createContext } from "react";

export interface SearchContextInterface {
  ordenBy?: string;
  Collection?: string;
  type?: string;
  productName?: string | null;
  typeSearchFilter:string;
  idCategories?:number | null;
  idAttributes?:number | string | null;
  idAttributes2?:number | string | null;
  idAttributes3?:number | string | null;
  page?: number;
  setSearchFilter(parm: SearchContextInterface): void;
}

export const DefaultValues: SearchContextInterface = {
  ordenBy: 'name',
  Collection: "",
  idCategories: null,
  idAttributes: null,
  idAttributes2: null,
  idAttributes3: null,
  type: "",
  productName: null,
  typeSearchFilter: "",
  page: 1,
  setSearchFilter: () => {},
};

export const SearchContext =
  createContext<SearchContextInterface>(DefaultValues);

SearchContext.displayName = "SearchContext";
