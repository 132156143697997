import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
    createStyles({
        contBanner:{
            margin:"6rem auto",
            maxWidth:"1200px",
            minHeight:"50vh",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",

            "& >div":{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            },
            "&>h1, h2": {
                fontSize:"3.4rem",
                textAlign:'center',
                marginTop:'40px',
                marginBottom:'30px',
            }
        },
        title:{
            fontSize:"3rem"
        },
        btnSend: {
            background: theme.palette.primary.main,
            color: "#ffffff",
            width: "fit-content",
            borderRadius: "0",
            fontSize: 16,
            padding: "0.6rem 1rem",
            border: `2px solid ${theme.palette.primary.main}`,
            marginTop: "1.6rem",
            transition: "0.4s ease all",
            fontWeight: 500,
            letterSpacing: ".05rem",
            [theme.breakpoints.down("xs")]: {
                fontSize: 15,
                marginTop: "1.2rem",
            },
            "&:hover": {
                backgroundColor: "#ffffff",
                color: theme.palette.primary.main,
            },
        },
        
    })
);
