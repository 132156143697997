import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
    createStyles({
        
        formControl: {
            marginBottom: ".5rem",
            "&>div": {
                position: "relative",
                paddingBottom: ".85rem",
            },
            "&>.MuiInputAdornment-root":{
                position: 'absolute',
                right: '1%',
                top: '50%'
            },
        },
        formControlSelect: {
            marginBottom: ".4rem",
            "&>div": {
                position: "relative",
                paddingBottom: ".85rem",
            },
        },
        btnSend: {
            background: theme.palette.primary.main,
            color: "#ffffff",
            width: "fit-content",
            borderRadius: "0",
            fontSize: 16,
            padding: "0.6rem 1rem",
            border: `2px solid ${theme.palette.primary.main}`,
            marginTop: "1.6rem",
            transition: "0.4s ease all",
            fontWeight: 500,
            letterSpacing: ".05rem",
            [theme.breakpoints.down("xs")]: {
                fontSize: 15,
                marginTop: "1.2rem",
            },
            "&:hover": {
                backgroundColor: "#ffffff",
                color: theme.palette.primary.main,
            },
        },
        contFormRegister: {
            "& label + .MuiInput-formControl": {
                marginTop: 14,
            },
            "& MuiFormLabel-root": {
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "100%",
            },
        },
        contTextNote: {
            marginTop: "1.6rem",
        },
        textNote: {
            lineHeight: "normal",
            textDecoration:"none",
            display: "block",
            fontFamily: "'Helvetica', sans-serif",
            fontSize: 14,
            textAlign: "center",
            color: "rgb(63 63 63 / 68%)",
            transition: ".4s all",
            "& span": {
                fontWeight: 700,
                cursor: "pointer",
                "&:hover": {
                color: theme.palette.info.main,
                },
        },
        "&:first-child": {
            paddingBottom: ".4rem",
        },
        },
        
    })
);
