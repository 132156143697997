import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function ShoppingIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 374.89 512">
      {/* <style>.cls-1{fill:#fff;}</style> */}
     <path fill="#fff" d="M374.63,442.21,347.4,142.56a16,16,0,0,0-16-14.56h-48V96a96,96,0,0,0-192,0v32h-48A16,16,0,0,0,27.5,142.56L.26,
     442.21A64,64,0,0,0,64,512H310.89a64,64,0,0,0,63.74-69.79ZM123.43,96a64,64,0,1,1,128,0v32h-128ZM334.5,469.6A31.73,31.73,
     0,0,1,310.86,480H64a32,32,0,0,1-31.84-34.91L58.06,160H91.43v48a16,16,0,0,0,32,0V160h128v48a16,16,0,0,0,32,0V160h33.38l25.92,
     285.12A31.58,31.58,0,0,1,334.5,469.6Z"
     />
    </SvgIcon>
  )
}
