import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      backgroundColor: "#e4e4e4",
      marginBottom: 0,
      width: "100%",
    },
    centerItems: {
      display: "flex",
      justifyContent: "space-between",
      color: "#77747d",
      fontSize: "15px",
      marginLeft: "auto",
      marginRight: "auto",
    },

    links: {
      "& a": {
        display: "list-item",
        color: "#77747d",
        textDecoration: "none",
        listStyle: "none",
        marginTop: 2,
      },
      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        width: "100%",
        justifyContent: "center",
        alingContent: "center",
        "& a": {
          textAlign: "center",
          marginTop: 0,
        },
      },
    },

    icons: {
      top: 0,
      alignItems: "center",
      display: "flex",
      flexWrap: "wrap",
      margin: "auto",
      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        justifyContent: "center",
        marginTop: 0,
      },
      justifyContent: "center",
    },
    facebook: {
      width: "30px",
      height: "29px",
    },
    instagram: {
      width: "30px",
      height: "23.5px",
    },
    youtube: {
      width: "30px",
      height: "29px",
    },
    pinterest: {
      width: "30px",
      height: "29px",
    },

    followUs: {
      fontFamily: theme.typography.fontFamily,
      marginTop: "2rem",
      marginBottom: "1.2rem",
      fontWeight: "bold",

      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        textAlign: "center",
        marginBottom: 15,
      },
    },
    aboutUs: {
      fontFamily: theme.typography.fontFamily,
      marginTop: "2rem",
      fontWeight: "bold",

      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        textAlign: "center",
      },
    },

    suscribe: {
      fontFamily: theme.typography.fontFamily,
      marginTop: "2rem",
      marginLeft: "1.4rem",
      fontWeight: "bold",
      cursor: "pointer",
      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        marginRight: "auto",
        textAlign: "center",
      },
    },
    containerItems: {
      width: "100%",
      height: "100%",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      display: "flex",
      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        alignItems: "center",
      },
    },

    incaGroup: {
      width: "100%",
    },
    whyAlpaca: {
      width: "100%",
    },
    btnIncaGroup: {
      display: "block",
      "&:hover": {
        background: "#e4e4e4",
      },
      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        marginTop: "1rem",
      },
    },
    btnWhyAlpaca: {
      display: "block",
      "&:hover": {
        background: "#e4e4e4",
      },
      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        marginTop: 0,
      },
    },
    terms: {
      paddingBottom: "15px",
      fontWeight: 500,
      "& a": {
        color: "rgba(66, 66, 65, 0.7)",
        cursor: "pointer",
      },
    },
  })
);
