import { ErrorMessage, Field } from "formik";
import React from "react";

import { TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "formik-material-ui-lab";

import CountryType from "Common/Types/CountryType";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";

interface Props {
  countries: CountryType[];
  touched: any;
  values: string;
}

const countryToFlag = (isoCode: string) => {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
};

export const CountriesAutocomplete: React.FC<Props> = ({
  countries,
  touched,
  values,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Field
        name="country"
        fullWidth
        component={Autocomplete}
        className={classes.form}
        classes={{ option: classes.option }}
        options={countries}
        getOptionLabel={(option: CountryType) =>
          option.label ? option.label : ""
        }
        renderOption={(option: CountryType) => (
          <>
            <span>{countryToFlag(option.code)}</span>
            {option.label}
          </>
        )}
        renderInput={(params: CountryType) => (
          <TextField
            {...params}
            label={t("Choose a country (*)")}
            error={touched.country && (values === "" || !values)}
          />
        )}
      />
      <ErrorMessage
        name="country"
        render={() => (
          <Typography className={classes.errorMessage}>
            {t("This field is required")} 
          </Typography>
        )}
      />
    </>
  );
};
