import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import backgroundBanner from "Assets/images/banner-bg.jpg";

export default makeStyles((theme: Theme) =>
  createStyles({
    contBanner: {
      paddingTop: "100px",
      position: "relative",
      height: "100vh",
      backgroundImage: `url(${backgroundBanner})`,
      backgroundSize: "cover",
    },
    banner: {
      position: "relative",
      width: "100%",
      height: "100vh",
    },
    captionBanner: {
      position: "absolute",
      marginBottom: "-4rem",
    },
    titleBanner: {
      color: "#77747d",
      textAlign: "center",
      fontWeight: 500,
      fontSize: "2.2rem",
      letterSpacing: ".35rem",
      textTransform: "uppercase",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.8rem",
        letterSpacing: ".1rem",
        "& br": {
          display: "none",
        },
      },
    },
    textBanner: {
      color: "#77747d",
      textAlign: "center",
      fontWeight: 500,
      fontSize: "medium",
      letterSpacing: ".35rem",
      textTransform: "none",
      [theme.breakpoints.down("xs")]: {
        fontSize: "medium",
        letterSpacing: ".1rem",
        "& br": {
          display: "none",
        },
      },
    },
    footerBanner: {
      color: "#77747d",
      textAlign: "center",
      fontWeight: 500,
      fontSize: "medium",
      letterSpacing: ".35rem",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "medium",
        letterSpacing: ".1rem",
        "& br": {
          display: "none",
        },
      },
    },
  })
);
