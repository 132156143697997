import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { Divider, Grid } from "@material-ui/core";
import {
  ProductDetail,
  StockModel,
  ColorModel,
  ToShoppingModel,
  ProductTypeDetails,
} from "Common/Types/Products";
import { colorMock } from "Common/mocks/mockProducts";
import StockList from "Components/StockList";
import ImageViewer from "Components/ImageViewer";
import DescriptionProduct from "Components/DescriptionProduct";
import ProductSummary from "Components/ProductSummary";
import { shoppingContext } from "Context/Shopping/ShoppingContext";
import ConfirmModal from "Components/ConfirmModal";
import { serializeProductToContext } from "Utils/Serializers/serializeProductDetailsColor";
import api from "Utils/AxiosConfig";
import useStyles, { ButtonExit } from "./styles";

export default function ProductDetails({ onClose, product }: ProductDetail) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { dispatch, shopping } = useContext(shoppingContext);
  const [colorList, setColorList] = useState<ColorModel[]>([]);
  const [addsummary, setAddSummary] = useState<ColorModel[]>([]);
  const [addShopping, setAddShopping] = useState<ToShoppingModel[]>([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const [colorListActive, setColorListActive] = useState<ColorModel[]>([]);
  const [editMode, setEditMode] = useState(false);
  const [productDetail, setProductDetail] = useState<ProductTypeDetails>();
  const [anyToShopping, setAnyToShopping] = useState(false);

  useQuery(
    ["product", product.id],
    async () => api.get(`api/products/products/${product.id}`),
    {
      onSuccess: (res) => {
        const data = res.data;
        setProductDetail(data);
        const serial = serializeProductToContext(data.colour_set);
        setColorList(serial);
      },
    }
  );

  const handleChangeColorList = (id: number) => {
    setColorList((prevState) =>
      prevState.map((elemt) =>
        elemt.id === id
          ? { ...elemt, active: true }
          : { ...elemt, active: false }
      )
    );
    setEditMode(false);
  };

  const handleDeleteSummary = (e: { id: number }) => {
    const findSummary = addsummary.filter((elemt) => elemt.id !== e.id);
    setAddSummary(findSummary);
  };

  useEffect(() => {
    const colorFilter: ColorModel[] =
      colorList.filter((element) => element.active) || [];
    setColorListActive(colorFilter);
  }, [colorList]);

  useEffect(() => {
    const colorFilter: ColorModel =
      colorList.find((element) => element.active) || colorMock;

    const findColorActive: ColorModel | undefined = colorList.find(
      (elemt) => elemt.active
    );

    const findSummaryActive = addsummary.find(
      (elemt) => elemt.color === findColorActive?.color
    );

    if (findSummaryActive !== undefined && !editMode) {
      setDisabled(true);
    } else if (editMode) {
      setDisabled(false);
    } else if (findSummaryActive === undefined) {
      setDisabled(false);
    }

    const findsColor: StockModel[] | [] = colorFilter?.stock.filter(
      (es) => es.myOrder > 0
    );

    setAnyToShopping(findsColor.length ? true : false);
  }, [colorList, addsummary, editMode]);

  const handleMyOrder = (
    type: string,
    item: StockModel,
    e: { target: { value: number } }
  ) => {
    if (type === "write") {
      setColorList((prevState) =>
        prevState.map((elemt) =>
          elemt.active
            ? {
                ...elemt,
                stock: elemt.stock.map((es) =>
                  es.size === item.size
                    ? {
                        ...es,
                        myOrder: Number(e.target.value),
                      }
                    : { ...es }
                ),
              }
            : { ...elemt }
        )
      );
    }
  };

  const resetMyOrder = () => {
    setColorList((prevState) =>
      prevState.map((elemt) => ({
        ...elemt,
        stock: elemt.stock.map((es) => ({
          ...es,
          myOrder: 0,
        })),
      }))
    );
  };

  const handleAddSummary = () => {
    const colorFilter: ColorModel =
      colorList.find((element) => element.active) || colorMock;

    const findsColor: StockModel[] | [] = colorFilter?.stock.filter(
      (es) => es.myOrder > 0
    );

    if (findsColor.length) {
      const toCard: string =
        findsColor
          .map((item: { size: string; myOrder: number }) => {
            return `${item.size}(${item.myOrder})`;
          })
          .toString() || "";

      const formatToCard = toCard.replaceAll(",", "-");
      const modelSet: ColorModel = {
        ...colorFilter,
        toCard: formatToCard,
        stock: findsColor,
      };

      const aux = [...addsummary];

      const filter = aux.filter((es) => es.id === colorFilter.id);
      if (!filter.length) {
        aux.push(modelSet);
      }

      const filters: ColorModel[] = aux.map((es) => {
        if (es.id === colorFilter.id) {
          es = modelSet;
        }
        return es;
      });
      setAddSummary(filters);
      let newFormatToShopping: ToShoppingModel[] = [];
      let currentShopping = [...shopping.products];
      filters.forEach((element) => {
        element.stock.forEach((item) => {
          newFormatToShopping.push({
            id: element.id || 0,
            code: uuidv4(),
            name: product.name,
            image: element.imageList[0].photoThumbnail,
            color: [element],
            toCard: element.toCard,
            size: item.size,
            sizeId: item.sizeId,
            avaliableStock: item.avaliableStock,
            myOrder: item.myOrder,
            price: product.price,
            subPrice: Number(product.price) * Number(item.myOrder),
            porini: productDetail?.porini ?? ""
          });
        });
      });

      const concat = newFormatToShopping.concat(currentShopping);
      var result: ToShoppingModel[] = [];
      concat.reduce((res: any, value: any) => {
        const combine = `${value.id}${value.sizeId}`;
        if (!res[combine]) {
          res[combine] = { ...value, myOrder: 0 };
          result.push(res[combine]);
        }
        res[combine].myOrder += value.myOrder;
        return res;
      }, {});
      setAddShopping(result);
    }
  };

  const addToCardShopping = () => {
    if (addShopping.length) {
      dispatch({
        type: "ADD_PRODUCTS",
        products: addShopping,
      });
      setShowConfirm(true);
      setAddSummary([]);
      resetMyOrder();
    }
  };

  return (
    <div className={classes.wrapper}>
      <ButtonExit onClick={() => onClose()}>X</ButtonExit>
      <Divider className={classes.dividerHeader} />
      <Grid container spacing={2}>
        <Grid item lg={6} xs={12}>
          {colorList.map((item, index) => (
            <div key={index}>
              {item.active && <ImageViewer imageList={item.imageList} />}
            </div>
          ))}
        </Grid>
        <Grid item lg={6} xs={12}>
          <DescriptionProduct
            changeColor={handleChangeColorList}
            product={productDetail}
            colorList={colorList}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.gridContainer}>
        <Grid item lg={6} xs={12}>
          <StockList
            anyToShopping={anyToShopping}
            isDisabled={isDisabled}
            handleAddSummary={handleAddSummary}
            changeOrder={handleMyOrder}
            colorList={colorListActive}
            editMode={editMode}
            changeEditMode={() => setEditMode(false)}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <ProductSummary
            changeEditMode={setEditMode}
            changeColorList={handleChangeColorList}
            deleteSummary={handleDeleteSummary}
            product={addsummary}
            addToCardShopping={addToCardShopping}
          />
          <ConfirmModal
            openModal={showConfirm}
            closeModal={() => setShowConfirm(false)}
            message={t(
              "all products have been added to the shopping cart successfully"
            )}
            title={t("Success")}
          />
        </Grid>
      </Grid>
    </div>
  );
}
