import { useState, useEffect } from "react";
import { Img } from "react-image";
import i18n from "i18next";

import { Card, Typography, Box, useMediaQuery } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { useTheme } from "@material-ui/core/styles";
import { ColorModel, ColourSet, ProductType } from "Common/Types/Products";
import ProductDetails from "Components/ProductDetails";
import useStyles from "./styles";
import ColorList from "Components/ColorList";
import React from "react";

export default function CardProduct(props: ProductType) {
  const { name, nameEs, nameEn, price, colourSet } = props;
  const theme = useTheme();

  const [colourSetStatus, setColourSetStatus] = useState<ColourSet[]>([]);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setColourSetStatus(colourSet);
  }, [props]);

  const handleOpenDetails = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setIsOpen(!isOpen);
    event.stopPropagation();
  };

  const handleChangeColorList = (
    id: number,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setColourSetStatus((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, active: true } : { ...item, active: false }
      )
    );
    event.stopPropagation();
  };

  return (
    <>
      <Card className={classes.wrapper} onClick={handleOpenDetails}>
        <div className={classes.container}>
          <div className={classes.photoContainer}>
            {React.Children.toArray(
              colourSetStatus.map((item, index) => (
                <div key={index}>
                  {item.active && (
                    <Img
                      src={
                        item.colourphotoSet && item.colourphotoSet.length > 0
                          ? item.colourphotoSet[0].photoThumbnail
                          : "https://plantillasdememes.com/img/plantillas/imagen-no-disponible01601774755.jpg"
                      }
                      alt="logo"
                      className={classes.photo}
                    />
                  )}
                </div>
              ))
            )}
          </div>
          {name && (
            <Typography variant="h4" className={classes.serviceToClient}>
              {i18n.language === "ES" ? nameEs : nameEn}
            </Typography>
          )}
          <div className={classes.priceButton}>
            {price && (
              <Typography
                variant="body1"
                component="p"
                className={classes.priceText}
              >
                USD {price}
              </Typography>
            )}
          </div>
          <Box className={classes.colorListContainer}>
            <ColorList
              changeColor={handleChangeColorList}
              colorList={colourSetStatus}
            />
          </Box>
        </div>
      </Card>
      {isOpen && (
        <Dialog
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              position: "relative",
              padding: "10px",
            },
          }}
          open={isOpen}
          maxWidth={"xl"}
          scroll={"body"}
        >
          <ProductDetails product={props} onClose={() => setIsOpen(!isOpen)} />
        </Dialog>
      )}
    </>
  );
}
