import {
  createStyles,
  fade,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: fade(theme.palette.info.main, 0.12),
      width: "100%",
      height: "100%",
      borderRadius: 0,
      boxShadow: "none",
      cursor: "pointer",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      padding: "1rem 1rem",
      alignItems: "center",
    },
    photoContainer: {
      width: "100%",
      marginBottom: "1rem",
    },
    photo: {
      minHeight: "100%",
      objectFit: "contain",
      objectPosition: "-50px",
      layout: "fill",
      maxHeight: "500px",
    },
    serviceToClient: {
      width: "100%",
      fontFamily: theme.typography.fontFamily,
      color: fade(theme.palette.text.primary, 0.6),
      fontSize: "1.2rem",
      fontWeight: 400,
      lineHeight: "normal",
      margin: "0 0 1rem",
      textAlign: "center",
    },
    priceButton: {
      backgroundColor: theme.palette.primary.main,
      display: "flex",
      width: "100%",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      lineHeight: "normal",
      padding: ".5rem 1.8rem",
    },
    priceText: {
      color: "#ffffff",
      fontSize: "1.1rem",
      fontWeight: "bold",
    },
    colorListContainer: {
      padding: "20px",
      maxWidth: "200px",
      overflowX: "scroll",
      display: "flex",
      "&::-webkit-scrollbar-track": {
        backgroundColor: fade(theme.palette.info.main, 0.05),
      },
      "&::-webkit-scrollbar": {
        height: "4px",
        backgroundColor: fade(theme.palette.info.main, 0.05),
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: fade(theme.palette.info.main, 0.6),
      },
    },
    dialogContainer: {
      borderRadius: "0px",
    },
  })
);
