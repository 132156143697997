import {
  createStyles,
  fade,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    internalPage: {
      marginTop: 82.4,
      width: "100%",
      margin: "0 auto",
      minHeight: "calc( 100vh - 82.4px)",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        minHeight: "auto",
        marginTop: 62.4,
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: 56,
      },
    },

    right: {
      width: "100%",
      padding: "2.5rem 2% 3.5rem 3%",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        paddingTop: "1.2rem",
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    titlePage: {
      fontSize: "1.8rem",
      fontWeight: "bold",
      paddingBottom: "30px",
      paddingTop: "30px",
    },
    description: {
      fontSize: "1.5rem",
      paddingBottom: "30px",
      color: "#77747d",
    },
    userData: {
      fontSize: "1.2rem",
      lineHeight: "3rem",
      color: "#77747df",
    },
    footerTitle: {
      fontSize: "1.4rem",
      borderTop: "dotted 5px",
      borderColor: theme.palette.primary.main,
      fontWeight: "bold",
      lineHeight: "3rem",
      color: "#77747d",
      marginTop: "3rem",
      paddingTop: "2rem"
    },
    footerData: {
      fontSize: "1.2rem",
      lineHeight: "2rem",
      color: "#77747d",
    },
  })
);
