import Carousel from "react-material-ui-carousel";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";

import GridCollections from "Components/GridCollections";
import TextDescription from "Components/TextDescription";
import useStyles from "./styles";

import backgroundBanner from "Assets/images/banner-bg.jpg";

export default function HomePage() {
  const classes = useStyles();
  const { t } = useTranslation();

  var items = [
    { image: backgroundBanner },
    { image: backgroundBanner },
    { image: backgroundBanner },
    { image: backgroundBanner },
  ];
  return (
    <>
      <Carousel
        indicatorContainerProps={{
          style: {
              position: "relative",
              top: "-40px",
              zIndex: 1
          }  
      }}
      >
        {items.map((item, i) => (
          <div
            key={i}
            style={{
              color: "#fff",
              height: "100vh",
              backgroundImage: `url(${item.image})`,
              backgroundSize: "cover",
              position: "relative",
            }}
          />
        ))}
      </Carousel>
      <Box
        display="flex"
        className={classes.banner}
        alignItems="center"
        justifyContent="center"
      >
        <Box>
          <Typography
            variant="h1"
            component="h2"
            className={classes.titleBanner}
          >
            {t("WELCOME TO")} <br /> {t("KUNA STOCK SERVICE")} <br /> <br />
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            className={classes.textBanner}
          >
            {t("Our new platform offers")}
            <br />
            {t("a improved service to our strategic partners,")}
            <br />
            {t(
              "simplifying and accelerating the acquisition and planification process."
            )}
            <br />
            {t("In KUNA STOCK SERVICE you can find")}
            <br />
            {t("the most iconic products of the brand,")}
            <br />
            {t("special sales opportunities and our latest collections.")}
            <br />
            <br />
          </Typography>
          <Typography variant="subtitle1" className={classes.footerBanner}>
            <i>{t("Feel the hands of the andes")}</i>
          </Typography>
        </Box>
      </Box>
      {/* <TextDescription /> */}
      <GridCollections />
    </>
  );
}
