import { ListShipping, ToShoppingModel } from "Common/Types/Products";

export const shoppingReducer = (
  state: ListShipping,
  action: { type: string; products: ToShoppingModel[] }
) => {
  switch (action.type) {
    case "ADD_PRODUCTS":
      return { products: action.products };
    default:
      return state;
  }
};
