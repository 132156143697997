import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Img } from "react-image";
import { useHistory } from "react-router-dom";

import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import { ProductSummaryProps, ColorModel } from "Common/Types/Products";
import edit from "Assets/images/edit.svg";
import { shoppingContext } from "Context/Shopping/ShoppingContext";
import deleteImg from "Assets/images/delete.svg";
import ColorList from "Components/ColorList";
import useStyles from "./styles";
export default function ProductSummary({
  product,
  deleteSummary,
  changeColorList,
  changeEditMode,
  addToCardShopping,
}: ProductSummaryProps) {
  const classes = useStyles();
  const { shopping } = useContext(shoppingContext);
  const productList = shopping.products || [];
  const { t } = useTranslation();
  const history = useHistory();
  const [activeBtn, setActiveBtn] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    if (product.length > 0) {
      setActiveBtn(true);
    } else {
      setActiveBtn(false);
    }
  }, [product]);

  const handleEditProductSummary = (id: number) => {
    changeColorList(id);
    changeEditMode(true);
  };

  const toShoppingPage = () => {
    if (activeBtn) {
      setOpenModal(true);
    } else {
      history.push("/shopping");
    }
  };
  return (
    <Box className={classes.wrapper}>
      <Typography variant="h3" color="primary" className={classes.title}>
        {t("PRODUCT SUMMARY")}
      </Typography>
      {product.map((item: ColorModel, index: number) => (
        <Box className={classes.listHeader} key={index}>
          <Box className={classes.colorName}>
            <ColorList changeColor={() => {}} colorListDetails={[item]} />
            <Typography
              variant="h5"
              color="secondary"
              className={classes.listFont}
            >
              {item.toCard.replace(/,/g, " - ")}
            </Typography>
          </Box>
          <div className={classes.myOrderList}>
            <Img
              src={edit}
              className={classes.img}
              onClick={() => {
                handleEditProductSummary(item.id || 0);
              }}
            ></Img>
            <Img
              src={deleteImg}
              className={classes.img}
              onClick={() => {
                deleteSummary(item);
              }}
            ></Img>
          </div>
        </Box>
      ))}
      <Box className={classes.buttonContainer}>
        {productList.length > 0 && (
          <Button className={classes.btnCheck} onClick={toShoppingPage}>
            <span>{t("CHECKOUT")}</span>
          </Button>
        )}

        {activeBtn ? (
          <>
            <Button
              className={classes.btnRegister}
              onClick={() => addToCardShopping()}
            >
              <span>{t("ADD TO CART")}</span>
            </Button>
          </>
        ) : null}
      </Box>
      {openModal && (
        <Dialog
          open={openModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t("Warning")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t(
                "You still have products not added to the shopping cart, if you continue they will be lost, do you want to continue anyway?"
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => history.push("/shopping")} color="primary">
              {t("Accept")}
            </Button>
            <Button onClick={() => setOpenModal(false)} color="primary">
              {t("Cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}
