// Import core modules
import React from "react";
import { useTranslation } from "react-i18next";

// Import Material Ui / Formik Related
import { Container, Box, Typography } from "@material-ui/core";

// Import Locals
import useStyles from "./styles";

export default function PrivacyPolicyPage() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container maxWidth="md" className={classes.contBanner}>
      <Box>
        <Typography variant="h1" className={classes.title}>
          {t("Privacy Policy")}
        </Typography>
      </Box>
      <Box className={classes.content}>
        <Typography className={classes.text}>
          INCALPACA TPX S.A. es una sociedad legalmente constituida y registrada
          en Perú con RUC N°20100226813 y domicilio social en Calle Cóndor Nro.
          100 Urb. Tahuaycani, Distrito de Sachaca, Provincia y Departamento de
          Arequipa; la cual valora y respeta la privacidad de sus clientes.
        </Typography>
        <Typography className={classes.text}>
          El tratamiento de la información personal recogida a través de la
          Plataforma es responsabilidad de la Empresa, y se sujeta a la Ley
          N°29733 –Ley de Protección de Datos Personales– y demás normas o
          regulaciones aplicables. Los fines para los cuales serán empleados
          estos datos se limitan al procesamiento de las órdenes de compra y
          comunicación con el Cliente, de modo que se pueda atender ágilmente
          sus inquietudes o suministrarle información relativa a la marca que
          pudiera ser de su interés.
        </Typography>
        <Typography className={classes.text}>
          <b>INFORMACIÓN RECOPILADA EN LÍNEA</b>
        </Typography>
        <Typography className={classes.text}>
          <b>Datos recogidos por medios automáticos</b>
        </Typography>
        <Typography className={classes.text}>
          La información recopilada en línea incluye aquella que se recoge por
          medios automáticos así como la que es de índole personal y que el
          cliente ingresa manualmente.
        </Typography>
        <Typography className={classes.text}>
          Los datos recogidos automáticamente durante la utilización de la
          Plataforma serán empleados con el único objetivo de obtener
          información estadística y comprobar el correcto funcionamiento de la
          tienda virtual. Algunos de estos incluyen, por ejemplo, las páginas
          que el cliente visualiza en el sitio web, información sobre el tipo de
          navegador, dirección IP del cliente, entre otros.
        </Typography>
        <Typography className={classes.text}>
          <b>Cookies</b>
        </Typography>
        <Typography className={classes.text}>
          La tienda online de KUNA hace uso de Google Analytics, herramienta que
          emplea ficheros temporales creados en cada dispositivo de navegación
          –denominados “cookies” –, para almacenar información con diferentes
          propósitos. Entre ellos, se encuentran el conocer las preferencias de
          compra del Cliente –tales como las categorías y productos vistos
          recientemente– y el almacenamiento de los productos que desee adquirir
          en la bolsa de compras.
        </Typography>
        <Typography className={classes.text}>
          <b>Datos de índole personal suministrados por el cliente</b>
        </Typography>
        <Typography className={classes.text}>
          El Cliente deberá registrar sus datos personales –en caso no hubiera
          creado previamente una cuenta– para poder ejecutar su Orden de Compra.
          La información personal que deberá consignar en el registro incluye
          sus nombres y apellidos, Documento Nacional de Identidad (DNI)/Carné
          de Extranjería (CE)/Registro Único de Contribuyentes (RUC), género,
          fecha de nacimiento, teléfono, dirección, distrito, provincia y
          departamento. Estos campos podrían variar dependiendo del país donde
          resida el Cliente.
        </Typography>
        <Typography className={classes.text}>
          Asimismo y dependiendo del método de pago seleccionado, se podrán
          recoger datos tales como número de la tarjeta de crédito, fecha de
          vencimiento y código de verificación de la misma.
        </Typography>
        <Typography className={classes.text}>
          <b>Uso de la información recopilada en línea</b>
        </Typography>
        <Typography className={classes.text}>
          La recopilación de información a través de la Plataforma tiene tres
          objetivos principales:
          <ul>
            <li>Tramitar y validar adecuadamente la Orden de Compra.</li>
            <li>
              Establecer una comunicación oportuna con el Cliente para brindarle
              el soporte necesario en caso se presente algún tipo de
              eventualidad.
            </li>
            <li>
              Mantenerlo actualizado respecto a los productos, servicios,
              campañas, ofertas u otra acción de marketing que lleve a cabo la
              marca.
            </li>
          </ul>
        </Typography>
        <Typography className={classes.text}>
          <b>Transmisión de la información</b>
        </Typography>
        <Typography className={classes.text}>
          Con el objetivo de brindarle al cliente un proceso de compra seguro,
          la Empresa posee un acuerdo con un proveedor externo de servicios de
          pago en línea.
        </Typography>
        <Typography className={classes.text}>
          Los datos suministrados por el cliente serán compartidos con el
          proveedor para fines exclusivos de prestación del servicio, los cuales
          incluyen el procesamiento de pagos y la validación de transacciones a
          través de un sistema anti fraude, a fin de minimizar el riesgo de
          suplantación de identidad de los tarjeta habientes.
        </Typography>
        <Typography className={classes.text}>
          Al efectuar un pago bajo la modalidad de “Pago con Tarjeta de
          Crédito”, el cliente autoriza el tratamiento confidencial de la
          información por el proveedor. Los datos personales, de la tarjeta de
          crédito y de la compra viajan encriptados en la red, quedando así
          protegidos.
        </Typography>
        <Typography className={classes.text}>
          <b>Derechos del Cliente</b>
        </Typography>
        <Typography className={classes.text}>
          El cliente, al registrar sus datos personales en la Plataforma, otorga
          expresamente su consentimiento a la Empresa para que estos sean
          utilizados para los fines descritos en la presente política. Esta
          información puede ser editada en aras de procesar correctamente
          futuras órdenes de compra.
        </Typography>
        <Typography className={classes.text}>
          El cliente, si así lo desea, puede dejar de recibir correos
          electrónicos con contenidos de marketing por parte de la marca. Para
          ello, deberá dirigirse a e-kuna@incalpaca.com. Se podrá prescindir de
          mensajes relativos al uso de la cuenta y/o demás notificaciones
          administrativas siempre y cuando esta sea eliminada, para la cual el
          cliente deberá comunicar esta decisión por correo electrónico.
        </Typography>
        <Typography className={classes.text}>
          <b>Actualización de la Política de Privacidad</b>
        </Typography>
        <Typography className={classes.text}>
          La Empresa se reserva el derecho de modificación sobre la presente
          Política de Privacidad. No obstante, los cambios se realizarán sobre
          la base de lo estipulado en la legislación aplicable y velando, ante
          todo, por la protección de los datos personales y satisfacción del
          cliente.
        </Typography>
        <Typography className={classes.text}>
          <b>CONTACTO</b>
        </Typography>
        <Typography className={classes.text}>
          De tener alguna pregunta, comentario o inquietud sobre la Políticas de
          Privacidad que regula el funcionamiento de esta Plataforma, el cliente
          podrá contactarse con la Empresa a través de la línea de Atención al
          Cliente +51 54 60 3000, anexo 504 (de Lunes a Viernes de 8 a 15 hrs) o
          mediante correo electrónico a e-kuna@incalpaca.com
        </Typography>
      </Box>
    </Container>
  );
}
