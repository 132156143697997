import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";

// Import Material Ui / Formik Related
import {
  AppBar,
  Container,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Input,
  Paper,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
} from "@material-ui/core";
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import MenuIcon from '@material-ui/icons/Menu';

// Import locals
import { DefaultValues, SearchContext } from "Context/Search/SearchContext";
import logoWhite from "Assets/images/logo.svg";
import {
  SearchIcon,
  ShoppingIcon,
} from "../CustomIcons";
import { shoppingContext } from "Context/Shopping/ShoppingContext";
import MenuSidebar from 'Components/MenuSidebar'
import useStyles from "./styles";
import { UserContext } from "Context/UserSesion/UserContext";
import setYupLocale from "Utils/Yup";

export default function Header() {
  const classes = useStyles();
  const stateDefaul = DefaultValues;
  const { setSearchFilter } = useContext(SearchContext);
  const [productName, setProductName] = useState("");

  const { shopping } = useContext(shoppingContext);
  const productList = shopping.products || [];
  const lngs = ["EN", "ES"];
  const { i18n, t } = useTranslation();

  const [header, setHeader] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const { token: isAuth } = useContext(UserContext);

  const anchorRef = useRef<HTMLButtonElement>(null);
  const [openMenu, setOpenMenu] = useState(false)

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event:  React.MouseEvent<Document, MouseEvent> ) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }
    setOpenMenu(false);
  };

  const listenScrollEvent = () => {
    if (window.scrollY > 90) {
      return setHeader(true);
    } else {
      return setHeader(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("kuna.stockservice-tokenAuth");
    if (!token) {
      if (
        location.pathname.includes("login") ||
        location.pathname.includes("register") ||
        location.pathname.includes("resetPassword") ||
        location.pathname.includes("activation") ||
        location.pathname.includes("resetNewPassword")
      ) {
        setShowSearchBar(false);
        history.push(location.pathname);
      } else {
        setShowSearchBar(true);
        history.push("/login");
      }
    } else {
      setShowSearchBar(true);
    }
  }, [location.pathname]);

  const handleChageLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setYupLocale();
  };

  const handlePushShearch = () => {
    setSearchFilter({ ...stateDefaul, productName: productName });
    history.push("/search");
  };

  const handleChangeInput = (e: { target: { value: string } }) => {
    setProductName(e.target.value);
  };

  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      listenScrollEvent();
    });
  }, []);

  const logout = () => {
    localStorage.removeItem("kuna.stockservice-tokenAuth");
    window.location.replace('')
    history.push('/login')
  }

  const currPath = window.location.pathname;
  const pathPrefix = "/";

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        className={classes.header}
        classes={{
          colorPrimary:
            header || currPath !== pathPrefix
              ? classes.scrollHeader
              : classes.noScrollHeader,
        }}
      >
        <Toolbar className={classes.boxHeader}>
          <Container maxWidth="xl">
            <nav className={classes.nav}>
              <div className={classes.right}>
                  {isAuth !== "" && (
                    <div className={classes.navMenu}>
                      <IconButton
                        className={openMenu ? classes.iconMenuActive : classes.iconMenu}
                        ref={anchorRef}
                        onClick={handleToggle}
                      >
                        <MenuIcon/>
                      </IconButton>
                      <Popper
                          open={openMenu}
                          anchorEl={anchorRef.current}
                          transition
                          disablePortal    
                          placement="bottom-start"                
                      >
                        {({ TransitionProps }) => (
                          <Grow {...TransitionProps}>
                            <Paper >
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                  autoFocusItem={openMenu}
                                >
                                  <MenuSidebar closeMenu={setOpenMenu}/>
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </div>
                  )}
                <Link to="/" className={classes.contLogo}>
                  <ReactSVG src={logoWhite} className={classes.logo} />
                  <div className={classes.line} />
                  <Typography variant="h4" className={classes.textLogo}>
                    STOCK SERVICE
                  </Typography>
                </Link>
              </div>
              <div className={classes.right}>
                <div className={classes.searchInput}>
                  <Hidden only={["sm", "xs"]}>
                    {showSearchBar && (
                      <>
                        <Paper className={classes.searchBar} >
                          <IconButton >
                            <SearchIcon fontSize={"small"} />
                          </IconButton>
                          <Input
                            placeholder={t("Search")}
                            value={productName}
                            onChange={(e) => handleChangeInput(e)}
                            disableUnderline={true}
                            onKeyPress= {(e) => {
                              if (e.key === 'Enter'){ 
                                handlePushShearch()
                                setProductName("");
                              }
                            }}
                          />
                      </Paper>
                      </>
                    )}
                  </Hidden>
                  <FormControl className={classes.formControl}>
                      <Select
                        value={i18n.language}
                        displayEmpty
                        className={classes.select}
                        inputProps={{
                          classes: {
                            icon: classes.icon,
                          },
                        }}
                      >
                        {React.Children.toArray(
                          lngs.map((lng: string, index: number) => (
                            <MenuItem
                              value={lngs[index]}
                              onClick={() => handleChageLanguage(lng)}
                            >
                              {lngs[index]}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </FormControl>
                  {showSearchBar && (
                    <>
                      <IconButton
                        className={classes.iconButtonClass}
                        href="/shopping"
                        target=""
                        aria-label="ShoppinCar"
                      >
                        <ShoppingIcon />
                        {productList.length > 0 && (
                          <div className={classes.lengthProductsContainer}>
                            <div className={classes.lengthProducts}>
                              {productList.length}
                            </div>
                          </div>
                        )}
                      </IconButton>
                      <IconButton
                        className={classes.iconButtonClass}
                        onClick={logout}
                        aria-label="Logout"
                      >
                        <ExitToAppOutlinedIcon />
                      </IconButton>
                    </>
                  )}
                </div>
              </div>
            </nav>
          </Container>
        </Toolbar>
      </AppBar>
    </>
  );
}
