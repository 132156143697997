import {
  GroupAttributeType,
  BackendGroupAttributeType,
  AttributeType,
  BackendAttribute,
} from "Common/Types/GroupAttributes";

export const serializeGroupAttributesTocontext = (
  groupAttribute: BackendGroupAttributeType[]
): GroupAttributeType[] => {
  const serializeGroupAttributeMap = groupAttribute.map((item) => {
    const serializeGroupAttribute: GroupAttributeType = {
      name: item.name,
      nameEn: item.name_en,
      nameEs: item.name_es,
      attributeSet: serializeAttributeSet(item.attribute_set),
    };
    return serializeGroupAttribute;
  });
  return serializeGroupAttributeMap;
};

const serializeAttributeSet = (
  attributeSet: BackendAttribute[]
): AttributeType[] => {
  const format = attributeSet.map((item) => {
    return {
      id: item.id,
      name: item.name,
      nameEs: item.name_es,
      nameEn: item.name_en,
    };
  });
  return format;
};
