import { FC, useState, useEffect } from "react";
import {
  DefaultValues,
  SearchContext,
  SearchContextInterface,
} from "./SearchContext";

const SearchProvider: FC = ({ children }) => {
  const [search, setSearchFilter] = useState<SearchContextInterface>(() => {
    const localData = localStorage.getItem("search");
    return localData ? JSON.parse(localData) : DefaultValues;
  });

  const getContext = () => ({
    ...search,
    setSearchFilter,
  });

  useEffect(() => {
    localStorage.setItem("search", JSON.stringify(search));
  }, [search]);

  return (
    <SearchContext.Provider value={getContext()}>
      {children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
