import axios from "axios";
import { FC, useState, useEffect, useContext } from "react";
import {
  DefaultValues,
  GeneralsContext,
  GeneralsContextInterface,
} from "./GeneralsContext";

import { serializeCategoryToContext } from "Utils/Serializers/serializeCategoryToContext";
import { serializeAtributesToContext } from "Utils/Serializers/serializeAtributesToContext";
import { serializeGroupAttributesTocontext } from "Utils/Serializers/serializeGroupAttributesToContext";
import { serializeHomepageImageToContext } from "Utils/Serializers/serializeHomepageImageToContext";

import { UserContext } from "../UserSesion/UserContext";

const GeneralsProvider: FC = ({ children }) => {
  const { token } = useContext(UserContext);

  const baseUrl: string = process.env.REACT_APP_API_LINK as string;
  const [generals, setGenerals] = useState<GeneralsContextInterface>(() => {
    const localData = localStorage.getItem("generals");
    return localData ? JSON.parse(localData) : DefaultValues;
  });

  const getContext = () => ({
    ...generals,
  });

  const getGenerals = async () => {
    const category = await axios.get(baseUrl + "api/products/categories", {
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    const homepageImage = await axios.get(baseUrl + "api/products/homepage-image/", {
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    const attributes = await axios.get(baseUrl + "api/products/attributes", {
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    const groupAttributes = await axios.get(baseUrl + "api/products/filters/", {
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    setGenerals((prev) => ({
      ...prev,
      categories: serializeCategoryToContext(category.data),
      attributes: serializeAtributesToContext(attributes.data),
      groupAttributes: serializeGroupAttributesTocontext(groupAttributes.data),
      homepageImages: serializeHomepageImageToContext(homepageImage.data),
    }));
  };

  useEffect(() => {
    if (token) {
      getGenerals();
    }
  }, [token]);

  useEffect(() => {
    localStorage.setItem("generals", JSON.stringify(generals));
  }, [generals]);

  return (
    <GeneralsContext.Provider value={getContext()}>
      {children}
    </GeneralsContext.Provider>
  );
};

export default GeneralsProvider;
