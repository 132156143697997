import { DefaulContext, ListShipping } from "Common/Types/Products";
import { createContext, useReducer, useEffect, FC } from "react";
import { shoppingReducer } from "./shoppingReducer";

const DefaultValue: ListShipping = {
    products: [],
};

const defaulContext: DefaulContext = {
  shopping: {
    products: [],
  },
  dispatch: () => {},
};

export const shoppingContext = createContext<DefaulContext>(defaulContext);
const ShoppingProvider: FC = ({ children }) => {
  const [shopping, dispatch] = useReducer(shoppingReducer, DefaultValue, () => {
    const localData = localStorage.getItem("kuna.stockservice-shopping");
    return localData ? JSON.parse(localData) : DefaultValue;
  });

  useEffect(() => {
    localStorage.setItem("kuna.stockservice-shopping", JSON.stringify(shopping));
  }, [shopping]);

  return (
    <shoppingContext.Provider value={{ shopping, dispatch }}>
      {children}
    </shoppingContext.Provider>
  );
};

export default ShoppingProvider;
